import React, { useEffect, useState } from 'react';
import Header from '../Header';

const translations = {
  pl: {
    title: "Polityka Prywatności",
    sections: [
      { heading: "1. Postanowienia ogólne", content: "Niniejsza Polityka prywatności określa zasady przetwarzania danych osobowych użytkowników serwisu internetowego, w którym możliwa jest rejestracja, czytanie książek dostępnych w serwisie oraz dodawanie własnych treści. Dbamy o prywatność naszych użytkowników i dokładamy wszelkich starań, aby przetwarzanie ich danych odbywało się zgodnie z obowiązującymi przepisami prawa, w tym zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 (RODO)." },
      { heading: "2. Administrator danych", content: "Administratorem danych osobowych jest [Nazwa firmy/Administrator], z siedzibą w [adres firmy]. Wszelkie pytania dotyczące przetwarzania danych osobowych prosimy kierować na adres e-mail: [adres e-mail]." },
      { heading: "3. Zakres zbieranych danych", content: "Podczas rejestracji w serwisie zbieramy następujące dane osobowe: Imię i nazwisko, Adres e-mail, Hasło (zaszyfrowane), Inne informacje niezbędne do korzystania z serwisu." },
      { heading: "4. Cele przetwarzania danych", content: "Dane osobowe użytkowników przetwarzane są w następujących celach: Realizacja usług oferowanych przez serwis, Zakładania i zarządzania kontem użytkownika, Kontakt z użytkownikiem, Analizy i ulepszania działania serwisu, Prowadzenia działań marketingowych (za zgodą użytkownika)." },
      { heading: "5. Podstawy prawne przetwarzania danych", content: "Podstawą przetwarzania danych są zgoda użytkownika (art. 6 ust. 1 lit. a RODO), wykonanie umowy (art. 6 ust. 1 lit. b RODO) oraz uzasadniony interes administratora (art. 6 ust. 1 lit. f RODO)." },
      { heading: "6. Przekazywanie danych osobowych", content: "Dane użytkowników nie będą przekazywane do krajów trzecich poza Europejski Obszar Gospodarczy, chyba że jest to konieczne do realizacji usług, przy zachowaniu odpowiednich zabezpieczeń." },
      { heading: "7. Przechowywanie danych", content: "Dane osobowe użytkowników będą przechowywane tak długo, jak jest to niezbędne do realizacji celów, dla których zostały zebrane, lub zgodnie z wymogami prawa." },
      { heading: "8. Prawa użytkownika", content: "Użytkownikowi przysługuje prawo do dostępu do swoich danych, ich poprawiania, usunięcia, ograniczenia przetwarzania, przenoszenia danych, a także prawo do sprzeciwu i cofnięcia zgody na przetwarzanie danych." },
      { heading: "9. Bezpieczeństwo danych", content: "Stosujemy odpowiednie środki techniczne i organizacyjne mające na celu zabezpieczenie danych osobowych użytkowników przed nieuprawnionym dostępem, w tym serwis jest hostowany na platformie Azure, która zapewnia wysoki poziom bezpieczeństwa danych." },
      { heading: "10. Pliki cookies", content: "Serwis korzysta z plików cookies, które są przechowywane na urządzeniu użytkownika i służą do ułatwienia korzystania z serwisu oraz prowadzenia analiz statystycznych." },
      { heading: "11. Zmiany w Polityce prywatności", content: "Administrator zastrzega sobie prawo do zmiany niniejszej Polityki prywatności w dowolnym momencie, informując o zmianach użytkowników serwisu." },
      { heading: "12. Kontakt", content: "Wszelkie pytania i uwagi dotyczące Polityki prywatności prosimy kierować na adres e-mail: [adres e-mail]." }
    ]
  },
  tr: {
    title: "Gizlilik Politikası",
    sections: [
      { heading: "1. Genel Hükümler", content: "Bu Gizlilik Politikası, kullanıcıların web sitesinde kayıt olma, mevcut kitapları okuma ve kendi içeriklerini ekleme gibi işlemler için kişisel verilerin işlenmesi kurallarını belirler. Kullanıcılarımızın gizliliğini önemsiyor ve verilerin geçerli mevzuata, özellikle Avrupa Birliği Genel Veri Koruma Yönetmeliği (GDPR) uyarınca işlenmesini sağlıyoruz." },
      { heading: "2. Veri Yöneticisi", content: "Kişisel verilerin yöneticisi [Şirket Adı/Yönetici] olup, adresi [şirket adresi] şeklindedir. Kişisel veri işleme ile ilgili tüm sorular için lütfen [e-posta adresi] adresine e-posta gönderin." },
      { heading: "3. Toplanan Veriler", content: "Kayıt sırasında, ad, e-posta adresi, şifre (şifrelenmiş) ve hizmeti kullanmak için gerekli diğer bilgileri toplarız." },
      { heading: "4. Verilerin İşlenme Amaçları", content: "Kullanıcı verileri, hizmet sunmak, kullanıcı hesabını yönetmek, kullanıcı ile iletişim kurmak, analiz ve geliştirme yapmak ve pazarlama faaliyetleri için işlenir." },
      { heading: "5. Verilerin İşlenme Hukuki Dayanağı", content: "Verilerin işlenmesi için yasal dayanaklar, kullanıcı onayı (GDPR Madde 6/1a), sözleşme ifası (GDPR Madde 6/1b) ve yöneticinin meşru menfaatleridir (GDPR Madde 6/1f)." },
      { heading: "6. Kişisel Verilerin Aktarılması", content: "Kullanıcı verileri, hizmetlerin gerçekleştirilmesi gerektiğinde, uygun güvenlik önlemleri altında, AB dışındaki üçüncü ülkelere aktarılabilir." },
      { heading: "7. Verilerin Saklanması", content: "Kullanıcı verileri, toplandıkları amaçları yerine getirmek için gereken süre boyunca veya yasal gerekliliklere göre saklanacaktır." },
      { heading: "8. Kullanıcı Hakları", content: "Kullanıcılar verilerine erişim, düzeltme, silme, işlemeyi kısıtlama, veri taşıma, itiraz ve veri işleme onayını geri çekme haklarına sahiptir." },
      { heading: "9. Verilerin Güvenliği", content: "Kullanıcı verilerini korumak için gerekli teknik ve organizasyonel önlemleri alıyoruz. Platform, veri güvenliği konusunda yüksek standartlara sahip Azure'da barındırılmaktadır." },
      { heading: "10. Çerezler", content: "Web sitemiz, kullanıcı deneyimini iyileştirmek ve istatistiksel analizler yapmak için cihazınıza çerezler depolar." },
      { heading: "11. Gizlilik Politikasında Değişiklikler", content: "Yönetici, kullanıcıları bilgilendirerek bu Gizlilik Politikasını değiştirme hakkını saklı tutar." },
      { heading: "12. İletişim", content: "Gizlilik Politikası ile ilgili tüm sorular ve yorumlar için lütfen [e-posta adresi] adresine yazınız." }
    ]
  },
  es: {
    title: "Política de Privacidad",
    sections: [
      { heading: "1. Disposiciones Generales", content: "Esta Política de Privacidad establece las normas de procesamiento de datos personales de los usuarios en el sitio web, donde es posible registrarse, leer libros disponibles en el servicio y agregar su propio contenido. Nos preocupamos por la privacidad de nuestros usuarios y nos aseguramos de que el procesamiento de sus datos cumpla con la legislación vigente, en particular, el Reglamento General de Protección de Datos de la UE (GDPR)." },
      { heading: "2. Administrador de Datos", content: "El administrador de datos personales es [Nombre de la Empresa/Administrador], con sede en [dirección de la empresa]. Cualquier pregunta sobre el procesamiento de datos personales debe dirigirse a [dirección de correo electrónico]." },
      { heading: "3. Alcance de los Datos Recopilados", content: "Recopilamos datos personales como nombre completo, dirección de correo electrónico, contraseña (cifrada) y otra información necesaria para el uso del servicio." },
      { heading: "4. Propósitos del Procesamiento de Datos", content: "Los datos personales de los usuarios se procesan para proporcionar servicios, gestionar la cuenta del usuario, comunicarse con el usuario, realizar análisis y mejorar el servicio, y realizar actividades de marketing (con el consentimiento del usuario)." },
      { heading: "5. Bases Legales para el Procesamiento de Datos", content: "Las bases legales para el procesamiento de datos son el consentimiento del usuario (artículo 6, apartado 1, letra a del GDPR), el cumplimiento del contrato (artículo 6, apartado 1, letra b del GDPR), y el interés legítimo del administrador (artículo 6, apartado 1, letra f del GDPR)." },
      { heading: "6. Transferencia de Datos Personales", content: "Los datos de los usuarios no se transferirán a terceros países fuera del EEE, a menos que sea necesario para la prestación de servicios, garantizando las medidas de seguridad adecuadas." },
      { heading: "7. Almacenamiento de Datos", content: "Los datos personales de los usuarios se almacenarán durante el tiempo necesario para los fines para los cuales fueron recopilados o según lo requiera la ley." },
      { heading: "8. Derechos del Usuario", content: "El usuario tiene derecho a acceder a sus datos, corregirlos, eliminarlos, restringir el procesamiento, transferir los datos, y el derecho a oponerse y retirar el consentimiento para el procesamiento de datos." },
      { heading: "9. Seguridad de los Datos", content: "Implementamos las medidas técnicas y organizativas necesarias para proteger los datos personales de los usuarios, incluyendo el alojamiento de los servicios en la plataforma de Azure, que proporciona un alto nivel de seguridad de los datos." },
      { heading: "10. Cookies", content: "El servicio utiliza cookies, que se almacenan en el dispositivo del usuario y sirven para facilitar el uso del servicio y realizar análisis estadísticos." },
      { heading: "11. Cambios en la Política de Privacidad", content: "El administrador se reserva el derecho de cambiar esta Política de Privacidad en cualquier momento, informando a los usuarios sobre los cambios." },
      { heading: "12. Contacto", content: "Cualquier pregunta o comentario sobre la Política de Privacidad debe dirigirse a [dirección de correo electrónico]." }
    ]
  },
  pt: {
    title: "Política de Privacidade",
    sections: [
      { heading: "1. Disposições Gerais", content: "Esta Política de Privacidade define as regras de processamento de dados pessoais dos usuários no site, onde é possível registrar-se, ler livros disponíveis no serviço e adicionar seu próprio conteúdo. Preocupamo-nos com a privacidade dos nossos usuários e garantimos que o processamento dos seus dados seja realizado em conformidade com a legislação vigente, particularmente o Regulamento Geral de Proteção de Dados da UE (GDPR)." },
      { heading: "2. Administrador de Dados", content: "O administrador de dados pessoais é [Nome da Empresa/Administrador], com sede em [endereço da empresa]. Quaisquer perguntas sobre o processamento de dados pessoais devem ser enviadas para o endereço de e-mail: [endereço de e-mail]." },
      { heading: "3. Escopo dos Dados Coletados", content: "Durante o registro no site, coletamos dados pessoais como nome completo, endereço de e-mail, senha (criptografada) e outras informações necessárias para o uso do serviço." },
      { heading: "4. Finalidades do Processamento de Dados", content: "Os dados pessoais dos usuários são processados para oferecer serviços, gerenciar a conta do usuário, comunicação com o usuário, análise e aprimoramento do serviço, e realização de atividades de marketing (com o consentimento do usuário)." },
      { heading: "5. Fundamentos Legais do Processamento de Dados", content: "As bases legais para o processamento dos dados são o consentimento do usuário (artigo 6º, parágrafo 1º, alínea a do GDPR), o cumprimento do contrato (artigo 6º, parágrafo 1º, alínea b do GDPR) e o interesse legítimo do administrador (artigo 6º, parágrafo 1º, alínea f do GDPR)." },
      { heading: "6. Transferência de Dados Pessoais", content: "Os dados dos usuários não serão transferidos para países terceiros fora do EEE, a menos que seja necessário para a prestação de serviços, garantindo medidas de segurança apropriadas." },
      { heading: "7. Armazenamento de Dados", content: "Os dados pessoais dos usuários serão armazenados pelo tempo necessário para os fins para os quais foram coletados ou conforme exigido pela legislação." },
      { heading: "8. Direitos do Usuário", content: "O usuário tem o direito de acessar seus dados, corrigi-los, eliminá-los, restringir o processamento, transferir os dados e o direito de se opor e retirar o consentimento para o processamento de dados." },
      { heading: "9. Segurança dos Dados", content: "Implementamos medidas técnicas e organizacionais necessárias para proteger os dados pessoais dos usuários, incluindo o uso da plataforma Azure, que oferece um alto nível de segurança dos dados." },
      { heading: "10. Cookies", content: "O serviço utiliza cookies, que são armazenados no dispositivo do usuário e servem para facilitar o uso do serviço e realizar análises estatísticas." },
      { heading: "11. Alterações na Política de Privacidade", content: "O administrador reserva-se o direito de alterar esta Política de Privacidade a qualquer momento, informando os usuários sobre as alterações." },
      { heading: "12. Contato", content: "Qualquer pergunta ou comentário sobre a Política de Privacidade deve ser enviado para o endereço de e-mail: [endereço de e-mail]." }
    ]
  },
  ru: {
    title: "Политика конфиденциальности",
    sections: [
      { heading: "1. Общие положения", content: "Настоящая Политика конфиденциальности определяет правила обработки персональных данных пользователей на сайте, где возможна регистрация, чтение доступных книг и добавление собственного контента. Мы заботимся о конфиденциальности наших пользователей и делаем все возможное, чтобы обработка их данных соответствовала действующему законодательству, в частности, Регламенту ЕС 2016/679 (GDPR)." },
      { heading: "2. Администратор данных", content: "Администратором персональных данных является [название компании/администратор], расположенная по адресу [адрес компании]. Все вопросы, касающиеся обработки персональных данных, направляйте на электронную почту: [электронный адрес]." },
      { heading: "3. Объем собираемых данных", content: "Во время регистрации на сайте мы собираем такие персональные данные, как полное имя, адрес электронной почты, пароль (зашифрованный) и другую информацию, необходимую для использования сайта." },
      { heading: "4. Цели обработки данных", content: "Персональные данные пользователей обрабатываются для предоставления услуг, управления учетной записью пользователя, связи с пользователем, анализа и улучшения работы сайта, проведения маркетинговых мероприятий (с согласия пользователя)." },
      { heading: "5. Правовые основания обработки данных", content: "Правовыми основаниями для обработки данных являются согласие пользователя (ст. 6(1)(а) GDPR), выполнение договора (ст. 6(1)(б) GDPR), и законный интерес администратора (ст. 6(1)(е) GDPR)." },
      { heading: "6. Передача персональных данных", content: "Данные пользователей не будут передаваться в третьи страны за пределы ЕЭЗ, за исключением случаев, когда это необходимо для предоставления услуг при соблюдении соответствующих мер безопасности." },
      { heading: "7. Хранение данных", content: "Персональные данные пользователей будут храниться столько времени, сколько необходимо для достижения целей, для которых они были собраны, или в соответствии с законодательными требованиями." },
      { heading: "8. Права пользователя", content: "Пользователь имеет право на доступ к своим данным, их исправление, удаление, ограничение обработки, перенос данных, а также право на возражение и отзыв согласия на обработку данных." },
      { heading: "9. Безопасность данных", content: "Мы применяем необходимые технические и организационные меры для защиты персональных данных пользователей. Хостинг услуг осуществляется на платформе Azure, которая обеспечивает высокий уровень безопасности данных." },
      { heading: "10. Cookies", content: "Сайт использует файлы cookie, которые сохраняются на устройстве пользователя и служат для облегчения использования сайта и проведения статистического анализа." },
      { heading: "11. Изменения в Политике конфиденциальности", content: "Администратор оставляет за собой право вносить изменения в настоящую Политику конфиденциальности в любое время, информируя пользователей о таких изменениях." },
      { heading: "12. Контакт", content: "Все вопросы и замечания относительно Политики конфиденциальности направляйте на электронную почту: [электронный адрес]." }
    ]
  },
  en: {
    title: "Privacy Policy",
    sections: [
      { heading: "1. General Provisions", content: "This Privacy Policy outlines the rules for processing users' personal data on the website, where it is possible to register, read available books, and add your own content. We care about the privacy of our users and ensure that data processing complies with applicable laws, including the EU General Data Protection Regulation (GDPR)." },
      { heading: "2. Data Controller", content: "The data controller is [Company Name/Administrator], based at [company address]. Any questions regarding personal data processing should be directed to [email address]." },
      { heading: "3. Scope of Data Collected", content: "During registration, we collect personal data such as full name, email address, password (encrypted), and other information necessary for using the service." },
      { heading: "4. Purposes of Data Processing", content: "Users' personal data is processed for the following purposes: service delivery, managing user account, contacting users, analyzing and improving the service, and conducting marketing activities (with user consent)." },
      { heading: "5. Legal Bases for Data Processing", content: "The legal bases for processing data are user consent (Art. 6(1)(a) GDPR), performance of a contract (Art. 6(1)(b) GDPR), and the legitimate interests of the administrator (Art. 6(1)(f) GDPR)." },
      { heading: "6. Transfer of Personal Data", content: "User data will not be transferred to third countries outside the EEA unless necessary for service delivery, ensuring appropriate safeguards." },
      { heading: "7. Data Retention", content: "Users' personal data will be retained as long as necessary for the purposes for which they were collected or as required by law." },
      { heading: "8. User Rights", content: "Users have the right to access their data, rectify it, delete it, restrict processing, data portability, and the right to object and withdraw consent for data processing." },
      { heading: "9. Data Security", content: "We implement appropriate technical and organizational measures to secure users' personal data. The service is hosted on Azure, which provides a high level of data security." },
      { heading: "10. Cookies", content: "The service uses cookies, which are stored on the user's device and are used to facilitate service use and conduct statistical analyses." },
      { heading: "11. Changes to the Privacy Policy", content: "The administrator reserves the right to change this Privacy Policy at any time, informing users about such changes." },
      { heading: "12. Contact", content: "Any questions or comments regarding the Privacy Policy should be directed to [email address]." }
    ]
  }
};

const getBrowserLanguage = () => {
  const lang = navigator.language.slice(0, 2);
  return translations[lang] ? lang : 'en';
};

const PrivacyPolicy = () => {
  const [language, setLanguage] = useState(getBrowserLanguage());
  const content = translations[language];

  return (
    <div>
      <Header />
      <div className="privacy-policy max-w-3xl mx-auto px-4 py-8 text-gray-800" style={{ marginTop: '100px' }}>
        <h1 className="text-4xl font-semibold mb-6 text-center">{content.title}</h1>
        {content.sections.map((section, index) => (
          <section className="mb-8" key={index}>
            <h2 className="text-2xl font-semibold mb-2">{section.heading}</h2>
            <p className="text-base leading-relaxed">{section.content}</p>
          </section>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
