import React from 'react';

const ArrowRightTopIconWhite = () => (
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 24 24"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="arrowRightTopIconTitle"
    stroke="#FFFFFF"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    color="#FFFFFF"
  >
    <title id="arrowRightTopIconTitle">Arrow Right Top</title>
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path d="M19 13V5h-8"></path>
      <path strokeLinecap="round" d="M19 5l-1 1"></path>
      <path d="M18 6L5 19"></path>
    </g>
  </svg>
);

export default ArrowRightTopIconWhite;
