import Section from "./Section";
import Heading from "./Heading";
import { service1, service2, service3, checkPremium } from "../assets";
import wordContextGif from "../assets/word_context.gif";
import historyDesktop from "../assets/history_desktop.gif";
import historyMobile from "../assets/history_mobile.gif";
import wordContextMobileGif from "../assets/word_context_mobile.gif";
import textHighlightingMobile from "../assets/text_highlighting_mobile.gif";
import textHighlightingDesktop from "../assets/text_highlighting_desktop.gif";
import explainInContextDesktop from "../assets/explain_in_context_desktop.gif";
import explainInContextMobile from "../assets/explain_in_context_mobile.gif";
import test from "../assets/test.gif";
import { useBrainwaveServices, brainwaveServicesIcons } from "../constantsLanding";
import {
  PhotoChatMessage,
  Gradient,
  VideoBar,
  VideoChatMessage,
} from "./design/Services";
import panda from '../assets/services/book_smart.webp';
import Generating from "./Generating";
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

const Services = () => {

  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 768);
  const { t } = useTranslation();
  const brainwaveServices = useBrainwaveServices();
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);
  
  return (
    <Section>
      <div className="container">
        <Heading
          title={t('aiPowered')}
          text={t('happReadUnlock')}
        />

        <div className="relative">
          <div className="relative z-1 flex items-center h-[39rem] mb-5 p-8 border border-n-1/10 rounded-3xl overflow-hidden lg:p-20 xl:h-[46rem]">
            <div className="absolute top-0 left-0 w-full h-full pointer-events-none md:w-3/5 xl:w-auto">
              <img
                className="w-full h-full object-cover md:object-right"
                style={{opacity: isMobile ? 0.1 : 0.4}}
                width={800}
                alt="Smart ebook reader"
                height={730}
                src={panda}
              />
            </div>

            <div className="relative z-1 max-w-[17rem] ml-auto" style={{color: 'black'}} >
              <h4 className="h5 mb-5" style={{marginTop: '30px', marginBottom: '-30px'}}>{t('smartEbook')}</h4>
              <p className="body-2 mb-[3rem] text-n-3" style={{color: 'black', fontSize: '1.1rem'}}>
                {t('potenialHappRead')}
              </p>
              <ul className="body-2">
                {brainwaveServices.map((item, index) => (
                  <li
                    key={index}
                    className="flex items-start py-4 border-t border-n-6"
                  >
                    <img width={24} height={24} src={checkPremium} />
                    <p className="ml-4" style={{fontSize: '1rem'}}>{item}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="relative z-1 grid gap-5 lg:grid-cols-2" id="how-to-use">
            <div className="p-4 bg-[#00B2FF] rounded-3xl overflow-hidden sm:min-h-[46rem] lg:min-h-[46rem]">
              <div className="py-12 px-4 xl:px-8">
                <h4 className="h4 mb-4">{t('textHighlighting')}</h4>
                <p className="body-2 mb-[2rem] text-[#FFFFFF] sm:text-lg" style={{fontSize: '1.3rem'}}>
                  {t('textHighlightingDesc')}
                </p>
              </div>

              <div className="relative bg-n-8 rounded-xl overflow-hidden mt-auto">
                <img
                  src={isMobile ? textHighlightingMobile : textHighlightingDesktop}
                  className="w-full h-auto object-cover"
                  alt="Scary robot"
                />
              </div>
            </div>

            
            <div className="p-4 bg-[#00B2FF] rounded-3xl overflow-hidden sm:min-h-[46rem] lg:min-h-[46rem] flex flex-col justify-between">
              <div className="py-12 px-4 xl:px-8">
                <h4 className="h4 mb-4">{t('wordContext')}</h4>
                <p className="body-2 mb-[2rem] text-[#FFFFFF]" style={{ fontSize: '1.3rem' }}>
                  {t('wordContextDesc')}
                </p>
              </div>

              <div
                className="relative bg-n-8 rounded-xl overflow-hidden mt-auto"
                style={{ marginBottom: '0px' }}
              >
                <img
                  src={isMobile ? wordContextMobileGif : wordContextGif}
                  className="w-full h-auto object-cover"
                  alt="Scary robot"
                />
              </div>
            </div>

            
            <div className="p-4 bg-[#00B2FF] rounded-3xl overflow-hidden sm:min-h-[46rem] lg:min-h-[46rem]">
              <div className="py-12 px-4 xl:px-8">
                <h4 className="h4 mb-4">{t('explainInContext')}</h4>
                <p className="body-2 mb-[2rem] text-[#FFFFFF]" style={{fontSize: '1.3rem'}}>
                  {t('explainInContextDesc')}
                </p>
              </div>

             <div className="relative bg-n-8 rounded-xl overflow-hidden mt-auto">
                <img
                  src={isMobile ? explainInContextMobile : explainInContextDesktop}
                  className="w-full h-auto object-cover"
                  alt="Scary robot"
                />
              </div>
            </div>

            <div className="p-4 bg-[#00B2FF] rounded-3xl overflow-hidden sm:min-h-[46rem] lg:min-h-[46rem] flex flex-col justify-between">
              <div className="py-12 px-4 xl:px-8">
                <h4 className="h4 mb-4">{t('flashcards')}</h4>
                <p className="body-2 mb-[2rem] text-[#FFFFFF]" style={{ fontSize: '1.3rem' }}>
                  {t('flashcardsDesc')}
                </p>
              </div>

              <div
                className="relative bg-n-8 rounded-xl overflow-hidden mt-auto"
                style={{ marginBottom: '0px' }}
              >
                <img
                  src={isMobile ? historyMobile : historyDesktop}
                  className="w-full h-auto object-cover"
                  alt="Scary robot"
                />
              </div>
            </div>

          </div>

          <Gradient />
        </div>
      </div>
    </Section>
  );
};

export default Services;
