import { useState, useEffect, useRef } from 'react';
import * as signalR from "@microsoft/signalr";

const useSignalRConnectionWordContextExplainInContext = (wordContextTranslationId, bookId) => {
  const [wordContextExplainInContext, setWordContextExplainInContext] = useState('');
  const connectionRef = useRef(null);

  useEffect(() => {
    // Initialize connection only once
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/api/signalrtransation`)
      .withAutomaticReconnect()
      .build();

    // Handle reconnection: resubscribe when reconnected
    connection.onreconnected(() => {
      console.log('Reconnected to SignalR hub.');
      subscribeToWordContextExplainInContext();
    });

    // Handle disconnection
    connection.onclose(() => {
      console.warn('SignalR connection closed.');
    });

    connection.start()
      .then(() => {
        console.log('SignalR connection established.');
        connectionRef.current = connection;
        subscribeToWordContextExplainInContext(); // Subscribe initially
      })
      .catch(error => {
        console.error('SignalR connection failed to start:', error);
      });

    return () => {
      if (connectionRef.current) {
        connectionRef.current.stop();
      }
    };
  }, []); // Empty dependency array ensures this runs only once

  // Function to handle subscription
  const subscribeToWordContextExplainInContext = () => {
    if (connectionRef.current) {
      connectionRef.current.invoke('Subscribe', `${bookId}-wordcontextexplainincontext`)
        .then(() => {
          console.log('Subscription successful!');
        })
        .catch(error => {
          console.error('Subscription failed:', error);
        });
    }
  };

  useEffect(() => {
    const connection = connectionRef.current;
    if (!connection) return;

    clearWordContextExplainInContext();

    const handler = (newMessage) => {
      const { id, message } = newMessage;
      if (id === wordContextTranslationId) {
        setWordContextExplainInContext(prev => [...prev, message]);
      }
    };

    connection.on('wordcontextexplainincontext', handler);

    return () => {
      connection.off('wordcontextexplainincontext', handler);
    };
  }, [wordContextTranslationId]); // Now this effect handles only updates to wordContextTranslationId

  const clearWordContextExplainInContext = () => {
    setWordContextExplainInContext('');
  };

  return { wordContextExplainInContext, clearWordContextExplainInContext };
};

export default useSignalRConnectionWordContextExplainInContext;
