import axios from 'axios';
import keycloakConfig from './Keycloak-config';
import { triggerError } from './errors/ErrorHandler.js'; // Import the error trigger

axios.defaults.withCredentials = true;

// Create an Axios instance
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    const token = keycloakConfig.token; // Get the token from Keycloak instance

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // Check if the error is not a 403 status code
    if (error.response && error.response.status !== 403 && error.response.status !== 401) {
      // Trigger error if it's not 403
      triggerError(error);
    }

    // Return the error as a rejected promise
    return Promise.reject(error);
  }
);

export default axiosInstance;
