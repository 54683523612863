import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../userContext/UserContext.js';

const I18nLoader = () => {
  const { i18n } = useTranslation();
  const { userData, loading } = useUser();

  // Map language names to i18n codes
    const languageShortcuts = {
        Polish: 'pl',
        English: 'en',
        Russian: 'ru',
        Spanish: 'es',
        Portuguese: 'pt',
        Turkish: 'tr',
        French: 'fr',
        Italian: 'it',
        Korean: 'ko',
        German: 'de',
        Japanese: 'ja',
        Hungarian: 'hu',
        Czech: 'cs',
        Slovak: 'sk',
        Dutch: 'nl',
        Greek: 'el',
        Swedish: 'sv',
        Finnish: 'fi',
        Danish: 'da',
        Norwegian: 'no',
    };

  // Function to determine default language for guests
  const getGuestLanguage = () => {
    const storedLanguage = localStorage.getItem('guestLanguage');
    if (storedLanguage) {
      return storedLanguage; // Use stored language if available
    }
    const browserLanguage = navigator.language.split('-')[0]; // Get browser's language (e.g., "en")
    return browserLanguage || 'en'; // Default to English if not supported
  };

  useEffect(() => {
    if (!loading) {
      if (userData && userData.interfaceLanguage) {
        // Set language based on logged-in user's preference
        i18n.changeLanguage(languageShortcuts[userData.interfaceLanguage]);
      } else {
        // Set language for guest users
        i18n.changeLanguage(getGuestLanguage());
      }
    }
  }, [i18n, loading, userData]);

  return null; // No UI needed for this component
};

export default I18nLoader;
