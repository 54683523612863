import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import parse from 'html-react-parser';
import './TextEbook.css';
import { useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUser } from '../userContext/UserContext.js';

const TextEbook = ({ onWordSelection, onWordUp, onWordDown, onWordOut, onWordOver, onWordRefsUpdate, onWordSpaceRefsUpdate, page, pageIndexes, onBookGenerated, fontFilledDots, bookData, onTextTouchEnd, onTextTouchStart, onTextTouchOver, bookContent, onTextMouseMove, theme, isBookEnded, isContentBookLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [fontSize, setFontSize] = useState();
  const [pageContent, setPageContent] = useState();
  const [isTextGenerated, setIsTextGenerated] = useState(false);
  const [isTextStyled, setIsTextStyled] = useState(false);
  const [isTextOnScreen, setIsTextOnScreen] = useState(false);
  const wordRefs = useRef([]);
  const wordSpaceRefs = useRef([]);
  const { userData, loading } = useUser();

useLayoutEffect(() => {
  if (pageContent) {
    setIsTextOnScreen(true);
  }else{
    setIsTextOnScreen(false);
  }
}, [pageContent]);

  useEffect(() => {
    if (userData) {
      setFontSize(userData.fontSize);
    }
  }, [userData]);

  useEffect(() => {
  }, [isTextStyled]);

  useEffect(() => {
    onWordRefsUpdate(wordRefs);
  }, [wordRefs]);

  useEffect(() => {
    onWordSpaceRefsUpdate(wordSpaceRefs);
  }, [wordSpaceRefs]);

  useEffect(() => {
    if (!theme) return;
    console.log("theme:", theme);
    setIsTextOnScreen(false);
    if (!bookContent || bookContent.content.length === 0) return;
    console.log("bookContent", bookContent)
    containerRef.current.style.justifyContent = page.next ? 'flex-start' : 'flex-end';
    setIsTextStyled(false);
    onBookGenerated(false);

    wordRefs.current = [];
    wordSpaceRefs.current = [];
    console.log("Start generating words");
    const parsedHTML = parse(bookContent.content, { replace: wrapAndFilterSpans });
    
    setPageContent(parsedHTML);
    containerRef.current.style.visibility = 'collapse';
    console.log("Finish generating words");
      setIsTextGenerated(true);
  }, [bookContent, theme]);


  
  useEffect(() => {
    if (isTextOnScreen && wordRefs.current && wordRefs.current.length > 0) {
      const containerRect = containerRef.current.getBoundingClientRect();
      let firstWordOnPage = null;
      let lastWordOnPage = null;
  
      wordRefs.current.forEach((child, i) => {
          const isOnScreen = getWordsOnScreen(containerRect, child);
          const idNum = parseInt(child.id, 10);
  
          if (isOnScreen) {
            child.classList.add('wordVisible');
            child.classList.remove('wordCollapse');
  
            if (firstWordOnPage === null || idNum < firstWordOnPage) {
              firstWordOnPage = idNum;
            }
  
            if (lastWordOnPage === null || idNum > lastWordOnPage) {
              lastWordOnPage = idNum;
            }
          } else {
            child.classList.add('wordCollapse');
            child.classList.remove('wordVisible');
          }
      });
  
      pageIndexes({ firstPageIndex: firstWordOnPage, lastPageIndex: lastWordOnPage });
      setIsTextGenerated(false);
      onBookGenerated(true);
      setIsTextStyled(true);
      containerRef.current.style.visibility = 'visible';
    }
  }, [isTextOnScreen]);

  const getWordsOnScreen = (parentRect, child) => {
    const childRect = child.getBoundingClientRect();
    return (
      (Math.floor(childRect.bottom) <= Math.floor(parentRect.bottom) || !page.next) &&
      Math.floor(childRect.top) >= Math.floor(parentRect.top)
    );
  };

  const wrapAndFilterSpans = (node) => {
    if (node.type === 'tag' && node.name === 'span') {
      return (
        <React.Fragment key={`frag-${node.attribs.id}`}>
          <span
            key={`span-${node.attribs.id}`}
            style={{
              borderBottom: '2px solid transparent',
              userSelect: "text",
              cursor: "pointer",
              userSelect: "none"
            }}
            {...node.attribs}
            ref={(el) => {
              if (el) {
                wordRefs.current[parseInt(node.attribs.id)] = el;
                const isSentenceTranslated = bookContent.wordValues.find(x => x.id === node.attribs.id)?.isSentenceTranslated;
                const isLightTheme = theme === 'light' || theme === 'white';
                el.style.backgroundColor = isSentenceTranslated ? (isLightTheme ? '#ADD8E6' : '#0F7DA1') : 'transparent';

                if (isSentenceTranslated && theme === 'dark') {
                  el.style.color = 'white';
                } else if (theme === 'dark') {
                  el.style.color = '#AAAAAA'
                } else {
                  el.style.color = 'black'
                }
                el.style.borderBottom = bookContent.wordValues.find(x => x.id === node.attribs.id)?.isWordTranslated ? '2px solid #00B2FF' : '2px solid transparent';
              }
            }}
          >
            {node.children && node.children.map(wrapAndFilterSpans)}
          </span>
          <span
            key={`space-${node.attribs.id}`}
            ref={(el) => {
              if (el) {
                wordSpaceRefs.current[parseInt(node.attribs.id)] = el;
                const isSentenceTranslated = bookContent.wordValues.find(x => x.id === node.attribs.id)?.isSentenceTranslated;
                const isLightTheme = theme === 'light' || theme === 'white';
                el.style.backgroundColor = isSentenceTranslated && bookContent.wordValues.find(x => x.id === node.attribs.id)?.isLastWordSentence === false ?
                  (isLightTheme ? '#ADD8E6' : '#0F7DA1') : 'transparent';
              }
            }}
            id={node.attribs.id}
            style={{ userSelect: "none" }}
          >&nbsp;</span>
        </React.Fragment>
      );
    }
    if (node.name === 'br') {
      return <div style={{ width: '100%' }} key={`br-${node.attribs.id || Math.random()}`}></div>
    }
    if (node.children && node.children.length > 0) {
      return React.createElement(
        node.name,
        node.attribs,
        node.children.map(wrapAndFilterSpans).filter(Boolean) // Remove null elements
      );
    }

    return node.data;
  };

  useEffect(() => {
    if (fontFilledDots == undefined) {
      return;
    }
    setFontSize(fontFilledDots);
    setIsTextGenerated(true);

  }, [fontFilledDots]);


  const handleWordClick = (e) => {
    if (e.target.textContent === '' || /^\s*$/.test(e.target.textContent)) {
      return;
    }
    if(e.target.nodeName != "SPAN"){
      return;
    }
    const selectionRect = e.target.getBoundingClientRect();
    const position = {
      x: selectionRect.left + window.scrollX + selectionRect.width / 2,
      y: selectionRect.top + window.scrollY + 30,
    };
    const context = {
      word: e.target.textContent.replace(/[^\p{L}\s’]/gu, ''),
      wordId: e.target.id,
      position: position,
      height: e.target.offsetHeight
    }
    onWordSelection(context, e);
  };

  const onMouseUpWord = (e) => {
    onWordUp(e);
  }
  const onTouchStart = (e) => {
    onTextTouchStart(e);
  }

  const onTouchOver = (e) => {
    onTextTouchOver(e);
  }

  const onTouchEnd = (e) => {
    onTextTouchEnd(e);
  }

  const onMouseDownWord = (e) => {
    onWordDown(e);
  }

  const onMouseWordMove = (e) => {
    onTextMouseMove(e);
  }
  const onMouseWordOver = (e) => {
    const selectionRect = e.target.getBoundingClientRect()
    const position = {
      x: selectionRect.left + window.scrollX + selectionRect.width / 2,
      y: selectionRect.top + window.scrollY - 40,
    };
    const context = {
      word: e.target.textContent,
      wordId: e.target.id,
      position: position
    }
    onWordOver(context);
  }

  const onMouseWordOut = (e) => {
    const selectionRect = e.target.getBoundingClientRect()
    const position = {
      x: selectionRect.left + window.scrollX + selectionRect.width / 2,
      y: selectionRect.top + window.scrollY + 30,
    };
    const context = {
      word: e.target.textContent,
      wordId: e.target.id,
      position: position
    }
    onWordOut(context);
  }

  const navigateToHome = () => {
    navigate(`/books`);
  };

  return (
    <>
      <div
        onMouseUp={onMouseUpWord}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchOver}
        onMouseDown={onMouseDownWord}
        onMouseOver={onMouseWordOver}
        onMouseMove={onMouseWordMove}
        onMouseOut={onMouseWordOut}
        onClick={handleWordClick}
        ref={containerRef}
        className="reader-container"
        style={{
          fontFamily: 'Georgia',
          fontSize: `${fontSize}px`,
          width: '80svh',
          visibility: isTextStyled ? 'visible' : 'collapse',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          gap: '1em'
        }}>
        {isBookEnded ? <div style={{display: 'contents', color: theme === 'dark' ? 'white' : 'black'}}>
          <h2>{t('congratulations')}!</h2>
        <span style={{fontSize: '1.2rem'}}>{t('finishBook')} &nbsp;
          <span style={{fontWeight: 'bold'}}>{bookData.title}</span>.&nbsp;
          </span>
          <span>{t('writtenBy')}:&nbsp;
          <span style={{fontWeight: 'bold'}}>{bookData.author}</span>
          </span>
          <span>{t('bookWords')}:&nbsp;
            <span style={{fontWeight: 'bold'}}>{bookData?.totalWordCount}</span>
          </span>
          <button onClick={navigateToHome} style={{background: '#616161', color: 'white',borderRadius: '15px', padding: '5px'}}>{t('backToBooksPage')}</button>
        </div>
         : pageContent}
      </div>
      {isContentBookLoading && 
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw', // Ensures full width on mobile
      height: '100svh', // Ensures full height on mobile
      display: 'grid',
      placeItems: 'center',
      backdropFilter: 'blur(5px)', // Adds blur effect
      zIndex: 9999, // Ensures it is above other elements
    }}
  >
    <CircularProgress sx={{ color: '#00B2FF' }} />
  </Box>
}

    </>
  );
};

export default TextEbook;
