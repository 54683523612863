import React, { useState, useEffect } from "react";
import axios from '../../components/AxiosConfig';
import { useNavigate } from "react-router-dom";
import Box from "./Box";
import "./PricingBox.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import UnlockPremiumIcon from "../../icons/UnlockPremiumIcon";
import { useTranslation } from 'react-i18next';

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width };
};

const PricingBoxModal = ({ visibility, onPremiumClose }) => {
  const navigate = useNavigate();
  const { width } = useViewport();
  const breakpoint = 768;
  const { t } = useTranslation();
  const [subscriptions, setSubscriptions] = useState({ free: {}, premium: {}, header: {} });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(visibility);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/subscription/plan`)
      .then(response => {
        setSubscriptions(response.data);
        setLoading(false);
        setShowModal(true);
      })
      .catch(error => {
        setError('Error fetching subscription data. Please try again later.');
        setLoading(false);
      });
  }, []);

  const handlePremiumClick = () => {
    setShowModal(false);
    onPremiumClose();
    navigate("/subscription/premium");
  };

  const handleBackClick = () => {
    setShowModal(false);
    onPremiumClose();
  };

  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert variant="danger">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleBackClick}
        size="xl"
        centered
        style={{ borderRadius: '15px', overflow: 'hidden', padding: 0 }}
      >
        <Modal.Header 
          closeButton 
          style={{ 
            borderBottom: 'none', 
            background: '#f0fbff', 
            borderTopLeftRadius: '25px', 
            borderTopRightRadius: '25px',
            textAlign: 'center'
          }}
        >
        </Modal.Header>
        <Modal.Body 
          style={{ 
            background: '#f0fbff', 
            borderBottomLeftRadius: '25px', 
            borderBottomRightRadius: '25px',
            maxHeight: '80vh', // Ensures the modal body does not exceed 80% of the viewport height
            overflowY: 'auto' // Adds scroll functionality when the content exceeds the modal body height
          }}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: window.innerWidth <= 600 ? '1.6rem' : '2.5rem',
              marginTop: window.innerWidth <= 600 ? '-1.2rem' : '-1.5rem',
              marginBottom: '10px',
              fontWeight: 'bold',
              flexDirection: 'row', // Always align the icon and text horizontally
            }}
          >
            <UnlockPremiumIcon style={{ marginRight: '8px' }} /> {/* Adds space between icon and text */}
            <span>{t('unlockUnlimited')}</span>
          </span>



          <div className="row justify-content-center mb-3 text-center">
            {width < breakpoint ? (
              <>
                <div className="col-md-6 col-lg-4 mb-4">
                  <Box
                    feature={subscriptions.premium.featuresPlan}
                    price={subscriptions.premium.price}
                    title={subscriptions.premium.title}
                    btnClass="btn-primary"
                    btnTitle={subscriptions.premium.btnTitle}
                    perMonth={subscriptions.premium.perMonth}
                    onClick={handlePremiumClick}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                  <Box
                    price={subscriptions.free.price}
                    title={subscriptions.free.title}
                    btnClass="btn-outline-primary"
                    btnTitle={subscriptions.free.btnTitle}
                    perMonth={subscriptions.free.perMonth}
                    feature={subscriptions.free.featuresPlan}
                    disabled={true}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-md-6 col-lg-4 mb-4">
                  <Box
                    price={subscriptions.free.price}
                    title={subscriptions.free.title}
                    btnClass="btn-outline-primary"
                    btnTitle={subscriptions.free.btnTitle}
                    perMonth={subscriptions.free.perMonth}
                    feature={subscriptions.free.featuresPlan}
                    disabled={true}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                  <Box
                    feature={subscriptions.premium.featuresPlan}
                    price={subscriptions.premium.price}
                    title={subscriptions.premium.title}
                    btnClass="btn-primary"
                    btnTitle={subscriptions.premium.btnTitle}
                    perMonth={subscriptions.premium.perMonth}
                    onClick={handlePremiumClick}
                    style={{ background: '#e5f9ff' }}
                  />
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PricingBoxModal;
