// ErrorContext.js
import React, { createContext, useState, useEffect } from 'react';
import { setErrorHandler } from './ErrorHandler';

export const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    setErrorHandler(setError);
  }, []);

  const value = {
    error,
    setError,
  };

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};
