import React, { useState, useEffect, useRef } from 'react';
import { useKeycloak } from "@react-keycloak/web";
import axios from '../components/AxiosConfig';

const useFetchBookData = (bookId) => {
 
  const [fetchError, setFetchError] = useState(null);
  const [bookData, setBookData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Book/store/${bookId}`);
        const data = response.data;
        setBookData(data);

      } catch (error) {
        setFetchError(error);
      }
    };

    fetchData();
  }, []);

  return { bookData };
};

export default useFetchBookData;