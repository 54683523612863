import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import './FailedPage.css';

const FailedPage = () => {
  const navigate = useNavigate();
  const handleRetryPaymentClick = () => {
    navigate('/subscription/premium');
  }
  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center min-vh-100"
      style={{ textAlign: 'center', padding: '20px' }}
    >
      <Row>
        <Col>
          <h1 className="display-4 text-danger" style={{ marginBottom: '20px' }}>
            Payment Failed
          </h1>
          <p className="lead" style={{ marginBottom: '30px' }}>
            We encountered an issue processing your payment. Please try again.
          </p>
          <Button className='retry-button' onClick={handleRetryPaymentClick}>
            Retry Payment
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default FailedPage;
