import Keycloak from 'keycloak-js';

const keycloakConfig = {
    url: process.env.REACT_APP_KC_URL,
    realm: 'book',
    clientId: 'react-client',
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;