import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { KeycloakContext } from './components/KeycloakProvider.js';
import { CircularProgress } from '@mui/material';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const { keycloak, authenticated } = useContext(KeycloakContext);
  const location = useLocation();

  useEffect(() => {
    if (authenticated === false && keycloak) {
      // User is not authenticated, initiate Keycloak login
      keycloak.login({ redirectUri: window.location.origin + location.pathname });
    }
  }, [authenticated, keycloak, location]);

  if (authenticated === true) {
    return <Component {...rest} />;
  }

    return <div className="spinner-container-language">
        <CircularProgress sx={{ color: '#00B2FF' }} />
    </div>;
};

export default PrivateRoute;
