import React from 'react';
import Header from '../Header';

const translations = {
  en: {
    title: 'Terms of Service',
    section1Title: '§1 General Provisions',
    section1Text: [
      '1. These terms (hereinafter: "Terms") define the rules for using the website that allows reading books (hereinafter: "Service").',
      '2. The Service is operated by [Company Name, address, Tax ID] (hereinafter: "Administrator").',
      '3. Each user, before starting to use the Service, is required to read and accept these Terms.',
      '4. Using the Service implies acceptance of all provisions of the Terms.',
    ],
    section2Title: '§2 Definitions',
    section2Text: [
      '1. User – a person using the Service.',
      '2. Account – an individual User profile, enabling access to Service resources.',
      '3. Ebook – an electronic version of a book available for reading on electronic devices.',
      '4. Subscription – a periodic fee allowing access to Service resources for a specified time.',
    ],
    section3Title: '§3 Rules of Using the Service',
    section3Text: [
      '1. The Service enables Users to read ebooks and access other materials published on the site.',
      '2. Registration in the Service is voluntary but required for full access to its functions, such as saving reading progress, access to paid content, or subscription.',
      '3. The User agrees to provide truthful and complete data during registration and to update this data if it changes.',
    ],
    section4Title: '§4 User Rights and Obligations',
    section4Text: [
      '1. The User agrees to use the Service in accordance with its intended purpose and to comply with applicable laws and these Terms.',
      '2. The User may not:',
    ],
    section4List: [
      'copy, share, or publish Service content without the Administrator’s permission;',
      'share Account data with others;',
      'use the Service in a way that may infringe the rights of other Users or the Administrator.',
    ],
  },
  pl: {
    title: 'Regulamin',
    section1Title: '§1 Postanowienia Ogólne',
    section1Text: [
      '1. Niniejszy regulamin (dalej: „Regulamin”) określa zasady korzystania z serwisu internetowego umożliwiającego czytanie książek (dalej: „Serwis”).',
      '2. Serwis jest prowadzony przez [Nazwa firmy, adres, NIP] (dalej: „Administrator”).',
      '3. Każdy użytkownik, przed rozpoczęciem korzystania z Serwisu, zobowiązany jest do zapoznania się i akceptacji niniejszego Regulaminu.',
      '4. Korzystanie z Serwisu oznacza akceptację wszystkich postanowień Regulaminu.',
    ],
    section2Title: '§2 Definicje',
    section2Text: [
      '1. Użytkownik – osoba korzystająca z Serwisu.',
      '2. Konto – indywidualny profil Użytkownika, umożliwiający korzystanie z zasobów Serwisu.',
      '3. Ebook – elektroniczna wersja książki dostępna do czytania na urządzeniach elektronicznych.',
      '4. Subskrypcja – okresowa opłata, umożliwiająca dostęp do zasobów Serwisu na określony czas.',
    ],
    section3Title: '§3 Zasady Korzystania z Serwisu',
    section3Text: [
      '1. Serwis umożliwia Użytkownikom czytanie ebooków oraz dostęp do innych materiałów publikowanych na stronie.',
      '2. Rejestracja w Serwisie jest dobrowolna, ale wymagana do pełnego korzystania z jego funkcji, takich jak zapisywanie postępów w czytaniu, dostęp do płatnych treści lub subskrypcji.',
      '3. Użytkownik zobowiązuje się do podawania prawdziwych i kompletnych danych podczas rejestracji oraz aktualizacji tych danych w razie ich zmiany.',
    ],
    section4Title: '§4 Prawa i Obowiązki Użytkownika',
    section4Text: [
      '1. Użytkownik zobowiązuje się do korzystania z Serwisu w sposób zgodny z jego przeznaczeniem oraz przestrzegania obowiązujących przepisów prawa i postanowień Regulaminu.',
      '2. Użytkownik nie może:',
    ],
    section4List: [
      'kopiować, udostępniać ani publikować treści Serwisu bez zgody Administratora;',
      'udostępniać danych do swojego Konta innym osobom;',
      'korzystać z Serwisu w sposób mogący naruszyć prawa innych Użytkowników lub Administratora.',
    ],
  },
  tr: {
    title: 'Hizmet Koşulları',
    section1Title: '§1 Genel Hükümler',
    section1Text: [
      '1. Bu şartlar (bundan sonra: "Şartlar"), kitap okuma imkanı sunan web sitesinin kullanım kurallarını tanımlar (bundan sonra: "Hizmet").',
      '2. Hizmet, [Şirket Adı, adres, Vergi Kimlik Numarası] tarafından işletilmektedir (bundan sonra: "Yönetici").',
      '3. Her kullanıcı, Hizmeti kullanmaya başlamadan önce bu Şartları okumalı ve kabul etmelidir.',
      '4. Hizmetin kullanılması, Şartların tüm hükümlerinin kabul edildiği anlamına gelir.',
    ],
    section2Title: '§2 Tanımlar',
    section2Text: [
      '1. Kullanıcı – Hizmeti kullanan kişi.',
      '2. Hesap – Hizmet kaynaklarına erişim sağlayan bireysel Kullanıcı profili.',
      '3. E-kitap – elektronik cihazlarda okumaya uygun bir kitabın elektronik versiyonu.',
      '4. Abonelik – belirli bir süre için Hizmet kaynaklarına erişim sağlayan dönemsel ücret.',
    ],
    section3Title: '§3 Hizmetin Kullanım Kuralları',
    section3Text: [
      '1. Hizmet, Kullanıcıların e-kitap okumasını ve sitede yayımlanan diğer materyallere erişimini sağlar.',
      '2. Hizmete kayıt olmak gönüllüdür, ancak okuma ilerlemesini kaydetme, ücretli içeriklere erişim veya abonelik gibi tam erişim sağlamak için gereklidir.',
      '3. Kullanıcı, kayıt sırasında doğru ve eksiksiz bilgi sağlamayı ve bu bilgileri güncellemeyi kabul eder.',
    ],
    section4Title: '§4 Kullanıcı Hak ve Yükümlülükleri',
    section4Text: [
      '1. Kullanıcı, Hizmeti amacına uygun olarak kullanmayı ve yürürlükteki yasalara ve bu Şartlara uymayı kabul eder.',
      '2. Kullanıcı, şunları yapamaz:',
    ],
    section4List: [
      'Yönetici’nin izni olmadan Hizmet içeriğini kopyalayamaz, paylaşamaz veya yayımlayamaz;',
      'Hesap bilgilerini başkalarıyla paylaşamaz;',
      'Hizmeti diğer Kullanıcıların veya Yöneticinin haklarını ihlal edebilecek şekilde kullanamaz.',
    ],
  },
  es: {
    title: 'Términos de Servicio',
    section1Title: '§1 Disposiciones Generales',
    section1Text: [
      '1. Estos términos (en adelante: "Términos") definen las reglas para usar el sitio web que permite la lectura de libros (en adelante: "Servicio").',
      '2. El Servicio es operado por [Nombre de la empresa, dirección, NIF] (en adelante: "Administrador").',
      '3. Cada usuario, antes de comenzar a usar el Servicio, está obligado a leer y aceptar estos Términos.',
      '4. Usar el Servicio implica la aceptación de todas las disposiciones de los Términos.',
    ],
    section2Title: '§2 Definiciones',
    section2Text: [
      '1. Usuario – una persona que utiliza el Servicio.',
      '2. Cuenta – un perfil de Usuario individual que permite el acceso a los recursos del Servicio.',
      '3. Ebook – una versión electrónica de un libro disponible para lectura en dispositivos electrónicos.',
      '4. Suscripción – una tarifa periódica que permite el acceso a los recursos del Servicio por un tiempo especificado.',
    ],
    section3Title: '§3 Reglas de Uso del Servicio',
    section3Text: [
      '1. El Servicio permite a los Usuarios leer ebooks y acceder a otros materiales publicados en el sitio.',
      '2. La inscripción en el Servicio es voluntaria, pero es necesaria para el acceso completo a sus funciones, como guardar el progreso de lectura, acceso a contenido de pago o suscripción.',
      '3. El Usuario acepta proporcionar datos veraces y completos durante el registro y actualizar dichos datos si cambian.',
    ],
    section4Title: '§4 Derechos y Obligaciones del Usuario',
    section4Text: [
      '1. El Usuario se compromete a usar el Servicio de acuerdo con su propósito y a cumplir con las leyes aplicables y estos Términos.',
      '2. El Usuario no puede:',
    ],
    section4List: [
      'copiar, compartir o publicar el contenido del Servicio sin el permiso del Administrador;',
      'compartir los datos de la Cuenta con otras personas;',
      'usar el Servicio de manera que pueda infringir los derechos de otros Usuarios o del Administrador.',
    ],
  },
  pt: {
    title: 'Termos de Serviço',
    section1Title: '§1 Disposições Gerais',
    section1Text: [
      '1. Estes termos (doravante: "Termos") definem as regras para usar o site que permite a leitura de livros (doravante: "Serviço").',
      '2. O Serviço é operado por [Nome da Empresa, endereço, CNPJ] (doravante: "Administrador").',
      '3. Cada usuário, antes de começar a usar o Serviço, é obrigado a ler e aceitar estes Termos.',
      '4. O uso do Serviço implica a aceitação de todas as disposições dos Termos.',
    ],
    section2Title: '§2 Definições',
    section2Text: [
      '1. Usuário – pessoa que utiliza o Serviço.',
      '2. Conta – perfil individual do Usuário, permitindo o acesso aos recursos do Serviço.',
      '3. Ebook – versão eletrônica de um livro disponível para leitura em dispositivos eletrônicos.',
      '4. Assinatura – taxa periódica que permite o acesso aos recursos do Serviço por um tempo especificado.',
    ],
    section3Title: '§3 Regras de Uso do Serviço',
    section3Text: [
      '1. O Serviço permite que os Usuários leiam ebooks e acessem outros materiais publicados no site.',
      '2. O registro no Serviço é voluntário, mas necessário para o acesso completo às suas funções, como salvar o progresso de leitura, acesso a conteúdo pago ou assinatura.',
      '3. O Usuário concorda em fornecer dados verdadeiros e completos durante o registro e atualizar esses dados caso mudem.',
    ],
    section4Title: '§4 Direitos e Obrigações do Usuário',
    section4Text: [
      '1. O Usuário concorda em usar o Serviço de acordo com seu propósito e em cumprir com as leis aplicáveis e com estes Termos.',
      '2. O Usuário não pode:',
    ],
    section4List: [
      'copiar, compartilhar ou publicar o conteúdo do Serviço sem a permissão do Administrador;',
      'compartilhar os dados da Conta com outras pessoas;',
      'usar o Serviço de maneira que possa infringir os direitos de outros Usuários ou do Administrador.',
    ],
  },
  ru: {
    title: 'Условия обслуживания',
    section1Title: '§1 Общие положения',
    section1Text: [
      '1. Настоящие условия (далее: "Условия") определяют правила использования веб-сайта, позволяющего читать книги (далее: "Сервис").',
      '2. Сервис управляется [Название компании, адрес, ИНН] (далее: "Администратор").',
      '3. Каждый пользователь, прежде чем начать использовать Сервис, обязан ознакомиться и принять настоящие Условия.',
      '4. Использование Сервиса означает принятие всех положений Условий.',
    ],
    section2Title: '§2 Определения',
    section2Text: [
      '1. Пользователь – лицо, использующее Сервис.',
      '2. Аккаунт – индивидуальный профиль Пользователя, позволяющий доступ к ресурсам Сервиса.',
      '3. Электронная книга – электронная версия книги, доступная для чтения на электронных устройствах.',
      '4. Подписка – периодическая плата, позволяющая доступ к ресурсам Сервиса на определенный срок.',
    ],
    section3Title: '§3 Правила использования Сервиса',
    section3Text: [
      '1. Сервис позволяет Пользователям читать электронные книги и получать доступ к другим материалам, опубликованным на сайте.',
      '2. Регистрация в Сервисе является добровольной, но необходимой для полного использования его функций, таких как сохранение прогресса чтения, доступ к платному контенту или подписке.',
      '3. Пользователь обязуется предоставлять достоверные и полные данные при регистрации и обновлять их в случае изменения.',
    ],
    section4Title: '§4 Права и обязанности Пользователя',
    section4Text: [
      '1. Пользователь обязуется использовать Сервис в соответствии с его назначением и соблюдать действующее законодательство и настоящие Условия.',
      '2. Пользователь не имеет права:',
    ],
    section4List: [
      'копировать, распространять или публиковать содержимое Сервиса без разрешения Администратора;',
      'передавать данные аккаунта другим лицам;',
      'использовать Сервис таким образом, который может нарушить права других Пользователей или Администратора.',
    ],
  },
};

const TermsOfService = () => {
  const browserLang = navigator.language.split('-')[0];
  const lang = translations[browserLang] ? browserLang : 'en';
  const t = translations[lang];

  return (
    <div>
      <Header />
      <div className="px-6 md:px-10 lg:px-20 mx-auto mt-24 max-w-4xl text-center">
        <h1 className="text-3xl font-bold mb-8">{t.title}</h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">{t.section1Title}</h2>
          {t.section1Text.map((text, index) => (
            <p key={index} className="mb-3">{text}</p>
          ))}
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">{t.section2Title}</h2>
          {t.section2Text.map((text, index) => (
            <p key={index} className="mb-3">{text}</p>
          ))}
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">{t.section3Title}</h2>
          {t.section3Text.map((text, index) => (
            <p key={index} className="mb-3">{text}</p>
          ))}
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">{t.section4Title}</h2>
          {t.section4Text.map((text, index) => (
            <p key={index} className="mb-3">{text}</p>
          ))}
          <ul className="list-disc list-inside mx-auto mb-3 space-y-2">
            {t.section4List.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
