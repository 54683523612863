import React from 'react';
import './Popup.css';

const Popup = ({ onClose }) => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className="popup-internet">
      <div className="popup-content-internet">
        <button className="close-button-internet" onClick={onClose}>×</button>
        <p>
          Connection to the server was lost. Please verify your internet connection and{' '}
          <span className="reload-link-internet" onClick={handleReload}>
            reload the page
          </span>.
        </p>
      </div>
    </div>
  );
};

export default Popup;
