import React from "react";
import { CheckIcon, CloseIcon } from '@mantine/core';

const Box = (props) => {
  const { title, btnClass, btnTitle, price, feature, perMonth, disabled, onClick } = props;

  return (
    <div className="col">
      <div className="card mb-4 rounded-3 shadow-sm">
        <div className="card-header py-3" style={{background:title === 'Premium'? '#00B2FF': '#96e4fd'}}>
          <h1  style={{color: 'white'}}>{title}</h1>
        </div>
        <div className="card-body" style={{background: title === 'Premium' ? '#e5f9ff' : 'white', border: title === 'Premium' ? '1px solid black' : '', borderRadius: '3px'}} >
          <h1 className="card-title pricing-card-title" style={{fontSize: '3.5rem'}}>
            {price} <small className="text-muted fw-light">{perMonth}</small>
          </h1>
          <ul className="list-unstyled mt-3 mb-4">
            {feature &&
              feature.map((data, index) => {
                const sentences = data.text.split('\n');
                return (
                  <li key={index} className="feature-item" style={{padding:5, textAlign: 'left'}}>
                    <div className="d-flex align-items-start">
                      {data.available ? (
                        <CheckIcon style={{color: 'green', height: '25px', width: '25px', marginRight: '10px'}}/>
                      ) : (
                        <CloseIcon size={25} style={{color: 'red', marginRight: '10px'}}/>
                      )}
                      <div>
                        {sentences.map((sentence, i) => (
                          <div key={i} className={i === 0 ? '' : 'feature-text'} dangerouslySetInnerHTML={{ __html: sentence }} style={{maxWidth:'17rem'}}>
                          </div>
                        ))}
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
          <button
            type="button"
            className={`w-100 btn btn-lg ${btnClass}`}
            disabled={disabled}
            style={{background: title === 'Premium' ?'#00B2FF' : 'grey', borderColor: title === 'Premium' ? '#00B2FF' : 'grey', color: 'white'}}
            onClick={onClick}
          >
            {btnTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Box;
