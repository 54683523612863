import * as React from "react";

function AdvancedBookChart(props) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="150" height="138" style={{ transform: "scale(0.3)", height: '100px'}}>
    <path d="M0 0 C49.5 0 99 0 150 0 C150 45.54 150 91.08 150 138 C100.5 138 51 138 0 138 C0 92.46 0 46.92 0 0 Z " fill="#F8FAFB" transform="translate(0,0)"/>
    <path d="M0 0 C3.72945537 0.6215759 4.68658403 1.55453914 7 4.5 C7.50793457 7.30786133 7.50793457 7.30786133 7.51757812 10.58203125 C7.53018677 11.81010498 7.54279541 13.03817871 7.55578613 14.3034668 C7.54862627 15.63981705 7.54042227 16.97616205 7.53125 18.3125 C7.53394577 19.68725865 7.53782889 21.06201544 7.54284668 22.43676758 C7.54873793 25.31512644 7.54019176 28.19298809 7.52148438 31.07128906 C7.49869389 34.75704016 7.51181913 38.44162622 7.53577423 42.12733459 C7.55024299 44.96529893 7.54562154 47.80298798 7.53526306 50.64096069 C7.5325987 51.99970641 7.53585541 53.35847728 7.54518127 54.7171936 C7.55536241 56.61744699 7.53708729 58.51778824 7.51757812 60.41796875 C7.51439575 61.49844482 7.51121338 62.5789209 7.50793457 63.69213867 C6.87669066 67.18165381 5.78516798 68.36620322 3 70.5 C0 71 0 71 -3 70.5 C-5.78516798 68.36620322 -6.87669066 67.18165381 -7.50793457 63.69213867 C-7.51111694 62.6116626 -7.51429932 61.53118652 -7.51757812 60.41796875 C-7.53649109 58.57585815 -7.53649109 58.57585815 -7.55578613 56.6965332 C-7.54862627 55.36018295 -7.54042227 54.02383795 -7.53125 52.6875 C-7.53394577 51.31274135 -7.53782889 49.93798456 -7.54284668 48.56323242 C-7.54873793 45.68487356 -7.54019176 42.80701191 -7.52148438 39.92871094 C-7.49869389 36.24295984 -7.51181913 32.55837378 -7.53577423 28.87266541 C-7.55024299 26.03470107 -7.54562154 23.19701202 -7.53526306 20.35903931 C-7.5325987 19.00029359 -7.53585541 17.64152272 -7.54518127 16.2828064 C-7.55536241 14.38255301 -7.53708729 12.48221176 -7.51757812 10.58203125 C-7.51439575 9.50155518 -7.51121338 8.4210791 -7.50793457 7.30786133 C-6.68261869 2.74551673 -4.52045795 0.75340966 0 0 Z " fill="#E9E6EE" transform="translate(120,28.5)"/>
    <path d="M0 0 C1.6934967 1.2010498 1.6934967 1.2010498 3 3 C3.38768005 5.35266113 3.38768005 5.35266113 3.40405273 8.15820312 C3.41673691 9.20939331 3.42942108 10.2605835 3.44248962 11.34362793 C3.43808617 13.04525085 3.43808617 13.04525085 3.43359375 14.78125 C3.4388356 15.94628052 3.44407745 17.11131104 3.44947815 18.31164551 C3.45590527 20.77627769 3.45455249 23.24094052 3.44604492 25.70556641 C3.43753321 29.48525347 3.46629226 33.26343103 3.49804688 37.04296875 C3.49965882 39.43489538 3.49908149 41.82682469 3.49609375 44.21875 C3.50732773 45.35316528 3.51856171 46.48758057 3.53013611 47.65637207 C3.52075516 48.70756226 3.51137421 49.75875244 3.50170898 50.84179688 C3.50145973 52.23054016 3.50145973 52.23054016 3.50120544 53.64733887 C3 56 3 56 1.19520569 57.83410645 C-2.10100778 59.58475517 -4.46156458 58.85184556 -8 58 C-10.55415923 55.44584077 -10.25705997 54.50006876 -10.2746582 50.95532227 C-10.28419022 49.90405655 -10.29372223 48.85279083 -10.30354309 47.76966858 C-10.30263168 46.63009201 -10.30172028 45.49051544 -10.30078125 44.31640625 C-10.30506638 43.14996078 -10.3093515 41.98351532 -10.31376648 40.78172302 C-10.31951802 38.31111298 -10.32000402 35.84048599 -10.31567383 33.36987305 C-10.3125159 29.58157558 -10.33595686 25.79406008 -10.36132812 22.00585938 C-10.36360115 19.60937599 -10.3643046 17.21289049 -10.36328125 14.81640625 C-10.372491 13.67885391 -10.38170074 12.54130157 -10.39118958 11.36927795 C-10.38496078 10.31486008 -10.37873199 9.2604422 -10.37231445 8.17407227 C-10.37294388 7.2452272 -10.3735733 6.31638214 -10.3742218 5.35939026 C-10 3 -10 3 -8.69548035 1.20005798 C-5.8605354 -0.80651103 -3.34422757 -0.3583101 0 0 Z " fill="#FF0000" transform="translate(99,40)"/>
    <path d="M0 0 C2.44719943 1.65291349 3.79788306 2.96059809 4.37329102 5.91967773 C4.53095227 8.36142641 4.55418965 10.77196811 4.53125 13.21875 C4.5350769 14.11364136 4.53890381 15.00853271 4.54284668 15.93054199 C4.5455652 17.82007982 4.53820816 19.70965532 4.52148438 21.59912109 C4.50007323 24.4901119 4.52128734 27.37861767 4.546875 30.26953125 C4.54423171 32.10677631 4.53910671 33.94401983 4.53125 35.78125 C4.53934692 36.64488159 4.54744385 37.50851318 4.55578613 38.39831543 C4.46268199 44.36203743 4.46268199 44.36203743 2.15429688 47.54492188 C-0.8000782 49.54039733 -2.50591826 49.58234696 -6 49 C-8.44719943 47.34708651 -9.79788306 46.03940191 -10.37329102 43.08032227 C-10.53095227 40.63857359 -10.55418965 38.22803189 -10.53125 35.78125 C-10.5350769 34.88635864 -10.53890381 33.99146729 -10.54284668 33.06945801 C-10.5455652 31.17992018 -10.53820816 29.29034468 -10.52148438 27.40087891 C-10.50007323 24.5098881 -10.52128734 21.62138233 -10.546875 18.73046875 C-10.54423171 16.89322369 -10.53910671 15.05598017 -10.53125 13.21875 C-10.53934692 12.35511841 -10.54744385 11.49148682 -10.55578613 10.60168457 C-10.46268199 4.63796257 -10.46268199 4.63796257 -8.15429688 1.45507812 C-5.1999218 -0.54039733 -3.49408174 -0.58234696 0 0 Z " fill="#FF0000" transform="translate(74,50)"/>
    <path d="M0 0 C1.69384766 1.08789062 1.69384766 1.08789062 3 3 C3.38818359 6.13867188 3.38818359 6.13867188 3.3984375 9.96875 C3.40274277 10.98382629 3.40274277 10.98382629 3.40713501 12.01940918 C3.40917397 13.44833209 3.40365569 14.87728302 3.39111328 16.30615234 C3.37505511 18.49249725 3.39096534 20.67699379 3.41015625 22.86328125 C3.40817379 24.25260824 3.40433004 25.64193409 3.3984375 27.03125 C3.39505371 28.29517578 3.39166992 29.55910156 3.38818359 30.86132812 C3 34 3 34 1.65869141 35.87695312 C-0.91310847 37.61823653 -2.9139627 37.93701965 -6 38 C-8.16040039 36.44555664 -8.16040039 36.44555664 -10 34 C-10.50170898 30.74780273 -10.50170898 30.74780273 -10.49609375 26.93359375 C-10.49754898 26.25829117 -10.49900421 25.58298859 -10.50050354 24.88722229 C-10.4988482 23.46424022 -10.48732752 22.04123958 -10.46655273 20.6184082 C-10.43754391 18.44079657 -10.44595884 16.26561324 -10.45898438 14.08789062 C-10.45274795 12.70311318 -10.44435774 11.3183434 -10.43359375 9.93359375 C-10.42384521 8.67603271 -10.41409668 7.41847168 -10.40405273 6.12280273 C-10 3 -10 3 -8.69165039 1.09008789 C-5.82451599 -0.75747382 -3.29443545 -0.35297523 0 0 Z " fill="#FF0000" transform="translate(50,61)"/>
    <path d="M0 0 C3.41142705 0.9303892 4.37983463 1.23815183 6.4375 3.9375 C7.08949413 7.21831928 6.94695087 10.53648155 6.9375 13.875 C6.96585938 14.78185547 6.99421875 15.68871094 7.0234375 16.62304688 C7.04301682 23.27512245 7.04301682 23.27512245 4.06640625 26.53125 C1.09868935 28.11873601 -0.27099946 28.69707705 -3.5625 27.9375 C-6.78483899 24.99407433 -7.83225747 22.68339305 -8.09375 18.43359375 C-8.10431349 16.74739675 -8.09331703 15.06094847 -8.0625 13.375 C-8.07796875 12.52486328 -8.0934375 11.67472656 -8.109375 10.79882812 C-8.08467513 6.86228708 -8.04567466 4.630433 -5.75390625 1.34375 C-3.5625 -0.0625 -3.5625 -0.0625 0 0 Z " fill="#FF0000" transform="translate(22.5625,71.0625)"/>
    </svg>
    );
}

export default AdvancedBookChart;