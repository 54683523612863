import React, { useEffect, useState } from 'react';

const DigitalGoodsExample = () => {
    const [isDigitalGood, setIsDigitalGood] = useState(false);
  useEffect(() => {
    const initializeDigitalGoods = async () => {
      if ('getDigitalGoodsService' in window) {
        //const digitalGoods = await window.getDigitalGoodsService();
        setIsDigitalGood(true);
        //console.log('Digital Goods API initialized:', digitalGoods);
      } else {
        setIsDigitalGood(false);
        console.log('Digital Goods API not supported in this environment.');
      }
    };

    initializeDigitalGoods();
  }, []);

  return <>
  { isDigitalGood && <div>Digital Goods API ON</div>}
  { !isDigitalGood && <div>Digital Goods API OFF</div>}
  </>;
};

export default DigitalGoodsExample;
