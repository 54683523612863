import { useState, useEffect, useRef } from 'react';
import * as signalR from "@microsoft/signalr";

const useSignalRConnectionWordContext = (wordExplanationId, bookId) => {
  const [wordContextDescription, setWordContextDescription] = useState('');
  const connectionRef = useRef(null);

  // Setup the SignalR connection once when the component mounts
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/api/signalrtransation`)
      .withAutomaticReconnect()
      .build();

    // Handle reconnection: resubscribe when reconnected
    connection.onreconnected(() => {
      console.log('Reconnected to SignalR hub.');
      subscribeToWordExplanations();
    });

    // Handle disconnection
    connection.onclose(() => {
      console.warn('SignalR connection closed.');
    });

    connection.start()
      .then(() => {
        console.log('SignalR connection established.');
        connectionRef.current = connection;
        subscribeToWordExplanations(); // Subscribe initially
      })
      .catch(error => {
        console.error('SignalR connection failed to start:', error);
      });

    return () => {
      if (connectionRef.current) {
        connectionRef.current.stop();
      }
    };
  }, []); // Empty dependency array ensures this runs only once

  // Function to handle subscription
  const subscribeToWordExplanations = () => {
    if (connectionRef.current) {
      connectionRef.current.invoke('Subscribe', `${bookId}-wordexplanation`)
        .then(() => {
          console.log('Subscription successful!');
        })
        .catch(error => {
          console.error('Subscription failed:', error);
        });
    }
  };

  // Listen to word explanation messages
  useEffect(() => {
    const connection = connectionRef.current;
    if (connection) {
      const handler = (newMessage) => {
        const { id, message } = newMessage;
        if (id === wordExplanationId) {
          setWordContextDescription(prev => [...prev, message]);
        }
      };

      connection.on('wordexplanation', handler);

      return () => {
        connection.off('wordexplanation', handler);
      };
    }
  }, [wordExplanationId]); // This depends on wordExplanationId, but doesn't recreate the connection

  const clearWordContextDescription = () => {
    setWordContextDescription('');
  };

  return { wordContextDescription, clearWordContextDescription };
};

export default useSignalRConnectionWordContext;
