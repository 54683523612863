import * as React from "react";

function SearchAdvanced(props) {
  return (
<svg height="16" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
    <path d="m30 30h-8v-26h8z" fill="#FF0000"/>
    <path d="m20 30h-8v-18h8z" fill="#FF0000"/>
    <path d="m10 30h-8v-12h8z" fill="#FF0000"/>
    <path d="m0 0h32v32h-32z" fill="none"/>
</svg>


  );
}

export default SearchAdvanced;
