import React, { useState } from 'react';
import './RemoveAccount.css';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import axios from '../../components/AxiosConfig';
import Spinner from 'react-bootstrap/Spinner';
import keycloakConfig from '../Keycloak-config';
import { useTranslation } from 'react-i18next';

const RemoveAccount = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleBackClick = () => {
    navigate('/books');
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmAccountRemoval = async () => {
    setIsLoading(true);
    setError(null);

    axios.delete(`${process.env.REACT_APP_API_URL}/api/user/removeAccount`)
      .then(response => {
        closeModal();
        setIsLoading(false);
        handleLogout();
        // setSuccessMessage('Your account has been successfully removed.');
        // // Optionally, redirect to login or home page after removal
        // navigate('/goodbye');
      })
      .catch(error => {
        console.error('There was an error removing the account!', error);
        setError('There was an error removing your account. Please try again later.');
        setIsLoading(false);
      });
  };
  const handleLogout = () => {
    keycloakConfig.logout({
      redirectUri: window.location.origin,
    });
  };

  return (
    <div className="page-container">
      <div className="header">
        <button className="back-button" onClick={handleBackClick}><FaArrowLeft /></button>
        <h1 style={{ color: '#333', fontSize: '1.5em', margin: '0', borderBottom: '1px solid #ddd' }}>
        {t('removeAccount')}
        </h1>
      </div>

      {successMessage ? (
        <h2 style={{color:'#00B2FF', fontSize: '1.5em', textAlign: 'center'}}>{successMessage}</h2>
      ) : (
        <>
          <p style={{ fontSize: '1.1em', textAlign: 'center' }}>
          {t('removeAccountInfo')}
            <br />
            <strong>{t('note')}:</strong> {t('subscriptionRemoveInfo')}
          </p>
          <button className="remove-account-button" onClick={openModal}>{t('removeAccount')}</button>
        </>
      )}

      {isModalOpen && (
        <div className="custom-modal-overlay">
          <div className="custom-modal">
            <p style={{ textAlign: 'center' }}>{t('permanentlyDelete')}</p>

            {error && <p className="error-message">{error}</p>}
            {isLoading ? (
              <div className="spinner-center-container">
                <Spinner animation="border" size="lg" style={{ color: '#00B2FF'}} role="status" aria-hidden="true" />
              </div>
            ) : (
              <>
                <div className="spinner-center-container">
                  <button onClick={confirmAccountRemoval} className="modal-confirm-button">
                  {t('yesRemoveAccount')}
                  </button>
                  <button onClick={closeModal} className="modal-close-button">{t('goBack')}</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RemoveAccount;
