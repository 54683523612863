import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaLock, FaDollarSign, FaLanguage, FaSignOutAlt, FaUser, FaStar, FaGlobe } from 'react-icons/fa';
import axios from '../../components/AxiosConfig';
import { Spinner } from 'react-bootstrap';
import keycloakConfig from '../Keycloak-config';
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import { useUser } from '../userContext/UserContext.js';

const Navbar = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isLoggingOut, setIsLoggingOut] = useState(false); // Spinner state
  const { userData, loading } = useUser();

  useEffect(() => {
    if (userData) {
      setEmail(userData.email);
    }
  }, [userData]);

  const handleLogout = (event) => {
    event.preventDefault();
    setIsLoggingOut(true); // Show spinner
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/user/logout`)
      .then((response) => {
        keycloakConfig.logout({
          redirectUri: window.location.origin,
        });
      })
      .catch((error) => {
        console.error('There was an error logging out!', error);
        setIsLoggingOut(false); // Hide spinner if logout fails
      });
  };

  return (
    <>
      {loading || isLoggingOut ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is on top
          }}
        >
          <Spinner animation="border" style={{ color: '#00B2FF' }} role="status" />
        </div>
      ) : null}
      <nav className="navbar">
        <ul className="navbar-menu">
          <li className="li-sidebar">
            <Link to="/remove-account">
              <div
                className="user-info"
                style={{ fontFamily: '"Source Code Pro", monospace', fontWeight: 600, maxWidth: '250px' }}
              >
                <FaUser className="user-icon" />
                <span className="user-email">{email}</span>
              </div>
            </Link>
          </li>
          <li className="li-sidebar">
            <Link
              to={userData.isSubscriptionActive ? '/manage-subscription' : '/subscription'}
              style={{ fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }}
            >
              {userData.isSubscriptionActive ? (
                <>
                  <FaDollarSign className="icon" />
                  {t('manageYourSubscription')}
                </>
              ) : (
                <>
                  <FaStar className="icon" />
                  {t('goPremium')}
                </>
              )}
            </Link>
          </li>
          <li className="li-sidebar">
            <Link to="/change-password" style={{ fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }}>
              <FaLock className="icon" />
              {t('changePassword')}
            </Link>
          </li>
          <li className="li-sidebar">
            <Link
              to="/change-interface-language"
              style={{ fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }}
            >
              <FaGlobe className="icon" />
              {t('changeInterfaceLanguage')}
            </Link>
          </li>
          <li className="li-sidebar">
            <Link to="/support" style={{ fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }}>
              <FaEnvelope className="icon" />
              {t('contactSupport')}
            </Link>
          </li>
          <li className="li-sidebar">
            <div className="logout-info" style={{ fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }}>
              <a href="/logout" onClick={handleLogout}>
                <FaSignOutAlt className="icon" />
                {t('logout')}
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
