import { useState, useEffect, useRef } from 'react';
import axios from '../components/AxiosConfig';

const useFetchData = (pageNumber, bookId) => {
  const [textArray, setTextArray] = useState([]);
  const [wordsArray, setWordsArray] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [isInitialLoadingDone, setIsInitialLoadingDone] = useState(false);
  const [isContentBookLoading, setIsContentBookLoading] = useState(true);
  const [bookContent, setBookContent] = useState();
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 768);
  const wordRefs = useRef([]);
  const wordSpaceRefs = useRef([]);
  useEffect(() => {
    
    function trimHtml(htmlContent, startFrom, endAt, reverse) {
      const parser = new DOMParser();
      const docP = parser.parseFromString(htmlContent, 'text/html');
      const spanNodes = docP.querySelectorAll('span');
      const spanCount = spanNodes.length;
      const start = Math.min(startFrom, endAt);
      const end = Math.max(startFrom, endAt);
      const spanStartNode = docP.querySelector(`span[id='${start}']`);
      const spanEndNode = docP.querySelector(`span[id='${end}']`);
    
      if (!spanStartNode || !spanEndNode) {
        return [htmlContent, spanCount];
      }
    
      // Collect parent node names
      const parentNamesFirst = [];
      const parentNamesLast = [];
      let parentNode = spanStartNode.parentNode;
      
      while (parentNode && parentNode.nodeName.toLowerCase() !== 'body') {
        const nodeName = parentNode.nodeName.toLowerCase();
        parentNamesFirst.push(`<${nodeName}>`);
        parentNode = parentNode.parentNode;
      }
    
      if (parentNamesFirst.length === 0) {
        parentNamesFirst.push('<p>');
      }
      
      let parentNodeLast = spanStartNode.parentNode;
      
      while (parentNodeLast && parentNodeLast.nodeName.toLowerCase() !== 'body') {
        const nodeName = parentNodeLast.nodeName.toLowerCase();
        parentNamesLast.push(`</${nodeName}>`);
        parentNodeLast = parentNodeLast.parentNode;
      }
    
      if (parentNamesLast.length === 0) {
        parentNamesLast.push('</p>');
      }

      // Adjust indices
      const startSearchString = spanStartNode.outerHTML;
      const endSearchString = spanEndNode.outerHTML;
    
      const startIndexOf = htmlContent.indexOf(startSearchString);
      const endIndexOf = htmlContent.indexOf(endSearchString);
      // Ensure no extra characters are included
      if (startIndexOf !== -1 && endIndexOf !== -1 && startIndexOf <= endIndexOf) {
        const resultHtml = htmlContent.substring(
          startIndexOf,
          endIndexOf + endSearchString.length// Add 7 for closing </span> tag
        );
    
        const filteredParentNamesFirst = parentNamesFirst.reverse().join('');
        const filteredParentNamesLast = parentNamesLast.reverse().join('');
        return [
          `${filteredParentNamesFirst}${resultHtml}${filteredParentNamesLast}`,
          endIndexOf,
        ];
      }
    
      return [htmlContent, spanCount];
    }
    
    
    

    
    const fetchData = async () => {
      try {
        const amountWordsRenderedOnScreen = isMobile ? 250 : 500;

        setIsContentBookLoading(true);
        setTextArray([]);
        setWordsArray([]);
        wordRefs.current = [];
        wordSpaceRefs.current = [];

        if(pageNumber.pageIndex === null){
          return;
        }
        let isCached = false;
        let cacheKey = "";
        const filteredContentKeys = Object.keys(localStorage).filter((key) => key.startsWith(bookId));
        filteredContentKeys.forEach(key => {
          const parts = key.split('_');
          const startIndex = parseInt(parts[1], 10);
          const endIndex = parseInt(parts[2], 10);
          //handle -1 pageIndex
          const pageIndex = pageNumber.pageIndex === -1 ? 1 : pageNumber.pageIndex;

          if(pageNumber.next){
            if(pageIndex >= startIndex && endIndex >= pageIndex + amountWordsRenderedOnScreen){
              isCached = true;
              cacheKey = key;
              return;
            }
          }else{
            if(Math.max(0, pageIndex - amountWordsRenderedOnScreen) >= startIndex && endIndex >= pageIndex){
              isCached = true;
              cacheKey = key;
              return;
            }
          }
          localStorage.removeItem(key)
        });

        const filteredTranslationsKeys = Object.keys(localStorage).filter((key) => key.startsWith(`translations_${bookId}`));
        filteredTranslationsKeys.forEach(key => {
          const parts = key.split('_');
          const startIndex = parseInt(parts[2], 10);
          const endIndex = parseInt(parts[3], 10);
          //handle -1 pageIndex
          const pageIndex = pageNumber.pageIndex === -1 ? 1 : pageNumber.pageIndex;

          if(pageNumber.next){
            if(pageIndex >= startIndex && endIndex >= pageIndex + amountWordsRenderedOnScreen){
              isCached = true;
              return;
            }
          }else{
            if(Math.max(0, pageIndex - amountWordsRenderedOnScreen) >= startIndex && endIndex >= pageIndex){
              isCached = true;
              return;
            }
          }
          localStorage.removeItem(key)
        });


        if(!isCached) {
          const startIndex = Math.max(pageNumber.pageIndex - 5000, 0);
          const [responseContent, responseTranslations] = await Promise.all([
            axios.get(`${process.env.REACT_APP_API_URL}/api/BookPage/${bookId}/${startIndex}/15000/${false}`),
            axios.get(`${process.env.REACT_APP_API_URL}/api/BookPage/historyWords/${bookId}/${startIndex}/15000/${false}`)
          ]);
      
          const pageContent = responseContent.data;
          const pageTranslations = responseTranslations.data;

          const cacheKeyContent = `${bookId}_${startIndex}_${startIndex + 15000}`;
          const currentDate = new Date().toISOString();
          const cacheKeyTranslation = `translations_${bookId}_${startIndex}_${startIndex + 15000}`;
          localStorage.setItem(`${cacheKeyContent}_${currentDate}`, JSON.stringify(pageContent));
          localStorage.setItem(`${cacheKeyTranslation}_${currentDate}`, JSON.stringify(pageTranslations));
          let end = 0;
          if(pageNumber.next){
            end = Math.min(pageNumber.pageIndex + amountWordsRenderedOnScreen, pageContent.totalWordCount);
          }else {
            end = Math.max(pageNumber.pageIndex - amountWordsRenderedOnScreen, 1);
          }

          //handle -1, 0 pageIndex
          const pageIndex = pageNumber.pageIndex === -1 || pageNumber.pageIndex === 0 ? 1 : pageNumber.pageIndex;
          const cachedBookContent = trimHtml(pageContent.content, pageIndex, end, !pageNumber.next);

          setBookContent({count: pageContent.count, furthestLocation: pageContent.furthestLocation, totalWordCount: pageContent.totalWordCount, wordValues: pageTranslations.words, content: cachedBookContent[0]});
          setIsContentBookLoading(false);
        }else {
          axios.put(`${process.env.REACT_APP_API_URL}/api/BookPage/position/${bookId}/${pageNumber.pageIndex}`)
          const cachedBooksData = localStorage.getItem(cacheKey);
          const parseCacheData = JSON.parse(cachedBooksData);
          const cachedBooksTranslationData = localStorage.getItem(`translations_${cacheKey}`);
          const parseCachedBooksTranslationData = JSON.parse(cachedBooksTranslationData);
          let end = 0;
          if(pageNumber.next){
            end = Math.min(pageNumber.pageIndex + amountWordsRenderedOnScreen, parseCacheData.totalWordCount);
          }else {
            end = Math.max(pageNumber.pageIndex - amountWordsRenderedOnScreen, 1);
          }
          //handle -1, 0 pageIndex
          const pageIndex = pageNumber.pageIndex === -1 || pageNumber.pageIndex === 0 ? 1 : pageNumber.pageIndex;
          const cachedBookContent = trimHtml(parseCacheData.content, pageIndex, end, !pageNumber.next);
          setBookContent({count: parseCacheData.count, furthestLocation: parseCacheData.furthestLocation, totalWordCount: parseCacheData.totalWordCount, wordValues: parseCachedBooksTranslationData.words, content: cachedBookContent[0]});
          setIsContentBookLoading(false);
        }
        setIsInitialLoadingDone(true);

      } catch (error) {
        setFetchError(error);
      }
    };

    fetchData();
    
  }, [pageNumber.pageIndex, pageNumber.next]);

  return { textArray, wordsArray, fetchError, wordRefs, wordSpaceRefs, bookContent, isContentBookLoading, isInitialLoadingDone };
};

export default useFetchData;
