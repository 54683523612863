import {
  benefitIcon1,
  benefitIcon2,
  benefitIcon3,
  benefitIcon4,
  benefitIcon5,
  benefitImage2,
  chromecast,
  disc02,
  discord,
  discordBlack,
  facebook,
  figma,
  file02,
  framer,
  homeSmile,
  instagram,
  notification2,
  notification3,
  notification4,
  notion,
  photoshop,
  plusSquare,
  protopie,
  raindrop,
  recording01,
  recording03,
  roadmap1,
  roadmap2,
  roadmap3,
  roadmap4,
  searchMd,
  slack,
  sliders04,
  telegram,
  twitter,
  yourlogo,
} from "../assets";
import { useTranslation } from 'react-i18next';
export const useNavigation = () => {
  const { t } = useTranslation();
  return [
    {
      id: "0",
      title: t('signIn'),
      url: "/dashboard",
      onlyMobile: true,
    },
    {
      id: "1",
      title: t('newAccount'),
      url: "/books",
      onlyMobile: true,
    },
    {
      id: "2",
      title: t('features'),
      url: "#features",
    },
    {
      id: "3",
      title: t('pricing'),
      url: "#pricing",
    },
    {
      id: "4",
      title: t('howToUse'),
      url: "#how-to-use",
    },
  ];
};

export const heroIcons = [homeSmile, file02, searchMd, plusSquare];

export const notificationImages = [notification4, notification3, notification2];

export const companyLogos = [yourlogo, yourlogo, yourlogo, yourlogo, yourlogo];

export const useBrainwaveServices = () => {
  const { t } = useTranslation();
  return [
  t('explanationInContext'),
  t('highlightTextTo'),
  t('uploadAnyEx'),
  t('smartWord'),
  t('overForFree')
  ]
};

export const brainwaveServicesIcons = [
  recording03,
  recording01,
  disc02,
  chromecast,
  sliders04,
];

export const roadmap = [
  {
    id: "0",
    title: "Voice recognition",
    text: "Enable the chatbot to understand and respond to voice commands, making it easier for users to interact with the app hands-free.",
    date: "May 2023",
    status: "done",
    imageUrl: roadmap1,
    colorful: true,
  },
  {
    id: "1",
    title: "Gamification",
    text: "Add game-like elements, such as badges or leaderboards, to incentivize users to engage with the chatbot more frequently.",
    date: "May 2023",
    status: "progress",
    imageUrl: roadmap2,
  },
  {
    id: "2",
    title: "Chatbot customization",
    text: "Allow users to customize the chatbot's appearance and behavior, making it more engaging and fun to interact with.",
    date: "May 2023",
    status: "done",
    imageUrl: roadmap3,
  },
  {
    id: "3",
    title: "Integration with APIs",
    text: "Allow the chatbot to access external data sources, such as weather APIs or news APIs, to provide more relevant recommendations.",
    date: "May 2023",
    status: "progress",
    imageUrl: roadmap4,
  },
];

export const collabText =
  "With smart automation and top-notch security, it's the perfect solution for teams looking to work smarter.";

export const collabContent = [
  {
    id: "0",
    title: "Seamless Integration",
    text: collabText,
  },
  {
    id: "1",
    title: "Smart Automation",
  },
  {
    id: "2",
    title: "Top-notch Security",
  },
];

export const collabApps = [
  {
    id: "0",
    title: "Figma",
    icon: figma,
    width: 26,
    height: 36,
  },
  {
    id: "1",
    title: "Notion",
    icon: notion,
    width: 34,
    height: 36,
  },
  {
    id: "2",
    title: "Discord",
    icon: discord,
    width: 36,
    height: 28,
  },
  {
    id: "3",
    title: "Slack",
    icon: slack,
    width: 34,
    height: 35,
  },
  {
    id: "4",
    title: "Photoshop",
    icon: photoshop,
    width: 34,
    height: 34,
  },
  {
    id: "5",
    title: "Protopie",
    icon: protopie,
    width: 34,
    height: 34,
  },
  {
    id: "6",
    title: "Framer",
    icon: framer,
    width: 26,
    height: 34,
  },
  {
    id: "7",
    title: "Raindrop",
    icon: raindrop,
    width: 38,
    height: 32,
  },
];

export const usePricing = () => {
  const { t } = useTranslation();
  return [
  {
    id: "0",
    title: t('basic'),
        description: t('basicDesc'),
    price: "0",
    features: [
      {
        feature: t('200Single'),
        isAvailable: true
      },
      {
        feature: t('10Phrase'),
        isAvailable: true
      },
      {
        feature: t('accessTo100'),
        isAvailable: true
      },
      {
        feature: t('uploadUp3'),
        isAvailable: true
      },
      {
        feature: t('unlimitedContext'),
        isAvailable: false
      },
      {
        feature: t('smartFlash'),
        isAvailable: false
      }
    ]
  },
  {
    id: "1",
    title: t('premium'),
      description: t('premiumDesc'),
    price: "6",
    features: [
      {
        feature: t('unlimitedSingleWord'),
        isAvailable: true
      },
      {
        feature: t('unlimitedPhrase'),
        isAvailable: true
      },
      {
        feature: t('accessOver50000'),
        isAvailable: true
      },
      {
        feature: t('unlimitedUpload'),
        isAvailable: true
      },
      {
        feature: t('unlimitedContext'),
        isAvailable: true
    },
      {
        feature: t('smartFlash'),
        isAvailable: true
        },
    ]
  }
]
};
export const useBenefits = () => {
  const { t } = useTranslation();
  return [
  {
    id: "0",
    title: t('accessBooks50000'),
    text: t('accessBooks50000Desc'),
    backgroundUrl: "../assets/benefits/card-1.svg",
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: "1",
    title: t('availableBooks'),
    text: t('availableBooksDesc'),
    backgroundUrl: "../assets/benefits/card-2.svg",
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "2",
    title: t('ownLibrary'),
    text: t('ownLibraryDesc'),
    backgroundUrl: "../assets/benefits/card-3.svg",
    iconUrl: benefitIcon3,
    imageUrl: benefitImage2,
  },
  {
    id: "3",
    title: t('uploadAnyBook'),
    text: t('uploadAnyBookDesc'),
    backgroundUrl: "../assets/benefits/card-4.svg",
    iconUrl: benefitIcon4,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "4",
    title: t('viewHistory'),
    text: t('viewHistoryDesc'),
    backgroundUrl: "../assets/benefits/card-5.svg",
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: "5",
    title: t('aiApp'),
    text: t('aiAppDesc'),
    backgroundUrl: "../assets/benefits/card-6.svg",
    iconUrl: benefitIcon5,
    imageUrl: benefitImage2,
  }]
};

export const socials = [
  {
    id: "0",
    title: "Discord",
    iconUrl: discordBlack,
    url: "#",
  },
  {
    id: "1",
    title: "Twitter",
    iconUrl: twitter,
    url: "#",
  },
  {
    id: "2",
    title: "Instagram",
    iconUrl: instagram,
    url: "#",
  },
  {
    id: "3",
    title: "Telegram",
    iconUrl: telegram,
    url: "#",
  },
  {
    id: "4",
    title: "Facebook",
    iconUrl: facebook,
    url: "#",
  },
];
