import React, { useEffect, useState, useRef } from "react";
import './TranslationTooltipHover.css';

const TranslationTooltipHover = ({ data, displayTooltip, onTooltipClose}) => {

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current && data.rect != undefined) {
      const rect = tooltipRef.current.getBoundingClientRect();
      const leftValueAsInt = parseInt(tooltipRef.current.style.left, 10);
      tooltipRef.current.style.left = `${leftValueAsInt + (rect.width /2)}px`
      if(data.rect.bottom >= rect.bottom){
        tooltipRef.current.style.top = `${rect.top - (rect.bottom - data.rect.bottom) - 30}px`
      }
      tooltipRef.current.style.visibility = 'visible'
    }
}, [data]);

  const style = {
    top: data.position.y,
    display: displayTooltip,
    borderRadius: '7px',
    maxWidth: '500px',
    visibility: 'collapse'
  };

  const handleClose = (e) => {
    e.target.parentNode.parentNode.style.display = 'none';
  };

  return (
    <div ref={tooltipRef} className="tooltip-hover" style={style}>
      <div className="tooltip-content">
        {data.translation}
      </div>
    </div>
  );
};

export default TranslationTooltipHover;
