import React, { useState } from 'react';
import { Autocomplete, TextField, Box, InputAdornment } from '@mui/material';
import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';

const LanguageAutocomplete = ({ languages, selectedLanguage, onLanguageChange, isValid = true }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();
  return (
    <Autocomplete
      options={languages}
      getOptionLabel={(option) => option.name}
      value={selectedLanguage}
      onChange={onLanguageChange}
      inputValue={searchTerm}
      onInputChange={(event, newInputValue) => setSearchTerm(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="medium"
          placeholder={t('selectLanguage')}
          InputProps={{
            ...params.InputProps,
            startAdornment: selectedLanguage && (
              <InputAdornment position="start">
                <Flag code={selectedLanguage.flag} style={{ width: '20px', marginLeft: '10px' }} />
              </InputAdornment>
            ),
            style: { fontSize: '16px' } // Adjust the font size here
          }}
          sx={{
            fontSize: '16px', // Adjust the font size here
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: isValid ? 'default' : 'red',
              },
              '&:hover fieldset': {
                borderColor: isValid ? 'default' : 'red',
              },
              '&.Mui-focused fieldset': {
                borderColor: isValid ? 'default' : 'red',
              },
            }
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props} display="flex" alignItems="center">
          <Flag code={option.flag} style={{ width: '20px', marginRight: '8px' }} />
          {option.name}
        </Box>
      )}
    />
  );
};

export default LanguageAutocomplete;
