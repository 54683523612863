import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../src/locales/en/translation.json';
import translationPL from '../src/locales/pl/translation.json';
import translationES from '../src/locales/es/translation.json';
import translationPT from '../src/locales/pt/translation.json';
import translationTR from '../src/locales/tr/translation.json';
import translationRU from '../src/locales/ru/translation.json';
import translationCS from '../src/locales/cs/translation.json';
import translationDA from '../src/locales/da/translation.json';
import translationDE from '../src/locales/de/translation.json';
import translationEL from '../src/locales/el/translation.json';
import translationFI from '../src/locales/fi/translation.json';
import translationFR from '../src/locales/fr/translation.json';
import translationHU from '../src/locales/hu/translation.json';
import translationIT from '../src/locales/it/translation.json';
import translationJA from '../src/locales/ja/translation.json';
import translationKO from '../src/locales/ko/translation.json';
import translationNL from '../src/locales/nl/translation.json';
import translationNO from '../src/locales/no/translation.json';
import translationSK from '../src/locales/sk/translation.json';
import translationSV from '../src/locales/sv/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  pl: {
    translation: translationPL,
  },
  es: {
    translation: translationES,
  },
  pt: {
    translation: translationPT,
  },
  tr: {
    translation: translationTR,
  },
  ru: {
    translation: translationRU,
  },
  cs: {
    translation: translationCS,
  },
  da: {
    translation: translationDA,
  },
  de: {
    translation: translationDE,
  },
  el: {
    translation: translationEL,
  },
  fi: {
    translation: translationFI,
  },
  fr: {
    translation: translationFR,
  },
  hu: {
    translation: translationHU,
  },
  it: {
    translation: translationIT,
  },
  ja: {
    translation: translationJA,
  },
  ko: {
    translation: translationKO,
  },
  nl: {
    translation: translationNL,
  },
  no: {
    translation: translationNO,
  },
  sk: {
    translation: translationSK,
  },
  sv: {
    translation: translationSV,
  },
};

const initializeI18n = () => {
      i18n
      .use(initReactI18next)
      .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false
        },
      });
};
initializeI18n();


export default initializeI18n;
