import React, { useState, useEffect } from 'react';
import './Page.css';
import { FaArrowLeft } from 'react-icons/fa';
import axios from '../../components/AxiosConfig';
import Spinner from 'react-bootstrap/Spinner';

const ManageSubscription = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    setIsLoadingData(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/subscription/current`)
    .then(response => {
      setSubscription(response.data);
      setIsLoadingData(false);
    })
    .catch(error => {
      setIsLoadingData(false);
      console.error('There was an error fetching the data!', error);
      setError('There was an error fetching the data. Please try again later.');
    });
  }, []);

  const handleBackClick = () => {
    window.location.href = '/books';
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmCancellation = async () => {
    setIsLoading(true);
    setError(null);

    axios.post(`${process.env.REACT_APP_API_URL}/api/subscription/cancel`)
      .then(response => {
        closeModal();
        setIsLoading(false);
        setSuccessMessage('Your subscription has been successfully canceled.');
        setSubscription(null); // Clear the subscription data
      })
      .catch(error => {
        console.error('There was an error canceling the subscription!', error);
        setError('There was an error canceling the subscription. Please try again later.');
        setIsLoading(false);
      });
  };

  return (
    <div className="page-container">
      <div className="header">
        <button className="back-button" onClick={handleBackClick}><FaArrowLeft /></button>
        <h1 style={{ color: '#333', fontSize: '1.5em', margin: '0', borderBottom: '1px solid #ddd' }}>
          Manage subscription
        </h1>
      </div>
      {isLoadingData ? (
        <Spinner as="span" className="modal-confirm-button-spinner" animation="border" role="status" aria-hidden="true" />
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : successMessage ? (
        <h2 style={{color:'#00B2FF', fontSize: '1.5em', textAlign: 'center'}}>{successMessage}</h2>
      ) : subscription ? (
        <>
        <p style={{ fontSize: '1.1em' }}>
          <strong>Start date:</strong> {new Date(subscription.startDate).toLocaleDateString()}
        </p>
        <p style={{ fontSize: '1.1em' }}>
          <strong>End date:</strong> {new Date(subscription.expirationDate).toLocaleDateString()}
        </p>

          <button className="cancel-subscription-button" onClick={openModal}>Cancel subscription</button>
        </>
      ) : (
        <p>No subscription data available.</p>
      )}

      {isModalOpen && (
        <div className="custom-modal-overlay">
          <div className="custom-modal">
            <h2 style={{ textAlign: 'center' }}>Confirm Cancellation</h2>
            <p style={{ textAlign: 'center' }}>Are you sure you want to cancel your subscription?</p>

            {error && <p className="error-message">{error}</p>}
            {isLoading ? (
              <div className="spinner-center-container">
                <Spinner animation="border" size="lg" style={{ color: '#00B2FF'}} role="status" aria-hidden="true" />
              </div>
            ) : (
              <>
                <div className="spinner-center-container">
                  <button onClick={confirmCancellation} className="modal-confirm-button">
                    Yes, Cancel
                  </button>
                  <button onClick={closeModal} className="modal-close-button">No, Go Back</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageSubscription;
