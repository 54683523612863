import React, { useState, useEffect } from 'react';
import Popup from './Popup';

const InternetStatusHandler = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowPopup(false);
    };
    const handleOffline = () => {
      setIsOnline(false);
      setShowPopup(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      {showPopup && (
        <Popup onClose={() => setShowPopup(false)} />
      )}
    </>
  );
};

export default InternetStatusHandler;
