import React, { useState, useEffect } from "react";
import axios from '../../components/AxiosConfig';
import { useNavigate } from "react-router-dom";
import Box from "./Box";
import "./PricingBox.css";
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert'
import UnlockPremiumIcon from "../../icons/UnlockPremiumIcon";
import { useTranslation } from 'react-i18next';

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width };
};

const PricingBox = () => {
  const navigate = useNavigate();
  const { width } = useViewport();
  const breakpoint = 768;
  const { t } = useTranslation();
  const [subscriptions, setSubscriptions] = useState({ free: {}, premium: {}, header: {} });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/subscription/plan`)
      .then(response => {
        setSubscriptions(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching subscription data. Please try again later.');
        setLoading(false);
      });
  }, []);

  const handlePremiumClick = () => {
    navigate("/subscription/premium");
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <div className="loading-container text-center my-5">
        <Spinner animation="border" role="status" variant="primary">
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert variant="danger">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="back-button-container">
        <button className="btn btn-back" onClick={handleBackClick}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#007bff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center" style={{marginTop: '5rem'}}>
      <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: window.innerWidth <= 600 ? '1.6rem' : '2.5rem',
              marginTop: window.innerWidth <= 600 ? '-1.2rem' : '-1.5rem',
              marginBottom: '10px',
              fontWeight: 'bold',
              flexDirection: 'row', // Always align the icon and text horizontally
            }}
          >
            <UnlockPremiumIcon style={{ marginRight: '8px' }} /> {/* Adds space between icon and text */}
            <span>{t('unlockUnlimited')}</span>
          </span>
      </div>
      <div className="row justify-content-center mb-3 text-center">
        {width < breakpoint ? (
          <>
            <div className="col-md-6 col-lg-4 mb-4">
              <Box
                feature={subscriptions.premium.featuresPlan}
                price={subscriptions.premium.price}
                title={subscriptions.premium.title}
                btnClass="btn-primary"
                btnTitle={subscriptions.premium.btnTitle}
                perMonth={subscriptions.premium.perMonth}
                onClick={handlePremiumClick}
              />
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Box
                price={subscriptions.free.price}
                title={subscriptions.free.title}
                btnClass="btn-outline-primary"
                btnTitle={subscriptions.free.btnTitle}
                perMonth={subscriptions.free.perMonth}
                feature={subscriptions.free.featuresPlan}
                disabled={true}
              />
            </div>
          </>
        ) : (
          <>
            <div className="col-md-6 col-lg-4 mb-4">
              <Box
                price={subscriptions.free.price}
                title={subscriptions.free.title}
                btnClass="btn-outline-primary"
                btnTitle={subscriptions.free.btnTitle}
                perMonth={subscriptions.free.perMonth}
                feature={subscriptions.free.featuresPlan}
                disabled={true}
              />
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Box
                feature={subscriptions.premium.featuresPlan}
                price={subscriptions.premium.price}
                title={subscriptions.premium.title}
                btnClass="btn-primary"
                btnTitle={subscriptions.premium.btnTitle}
                perMonth={subscriptions.premium.perMonth}
                onClick={handlePremiumClick}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PricingBox;
