import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SettingsIcon from '@mui/icons-material/Settings';
import BookSettings from '../bookSettings/BookSettings';
import './EbookBar.css';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { Visibility } from '@material-ui/icons';
import { useUser } from '../userContext/UserContext.js';

const pages = [];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function EbookBar({ onFontSizeFilledDots, bookData, onThemeChange, isBookSettingsClose, onBookSettingsClosed, isBookEnded, textBookGenerated, isContentBookLoading  }) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [showBookSettings, setShowBookSettings] = React.useState(false);
    const [currentTheme, setCurrentTheme] = React.useState('');
    const navigate = useNavigate();
    const { userData, loading } = useUser();
    useEffect(() => {
        if (isBookSettingsClose) {
            setShowBookSettings(!isBookSettingsClose);
            onBookSettingsClosed('');
        }
    }, [isBookSettingsClose]);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        const selectionRect = event.target.getBoundingClientRect();
        const position = {
            x: selectionRect.left - 210,
            y: selectionRect.top + window.scrollY + 40,
        };
        // setPosition(position);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const onFontSizeChange = (filledDots) => {
        onFontSizeFilledDots(filledDots)
    };
    const handleThemeChange = (theme) => {
        setCurrentTheme(theme);
        onThemeChange(theme)
    };
    const toggleBookSettings = () => {
        setShowBookSettings(!showBookSettings); // Toggle visibility of BookSettings
    };
    const onSettingsClose = () => {
        setShowBookSettings(false);
    }

    return (
        <AppBar position="static" sx={{ backgroundColor: 'transparent', height: 55, border: 'none' }} elevation={0}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
          <AdbIcon sx={{ display: { xs: 'none', md: 'flex', color: '#00B2FF' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              color: '#00B2FF'
            }}
          >
            LOGO
          </Typography>
            </Link> */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        {/* <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton> */}
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton onClick={toggleBookSettings} sx={{ p: 0, mr: 0, mb: 0, color: "#96a3ac" }}>
                            <SettingsIcon className='settingsIcon' style={{ width: '30px', height: '30px', visibility: isBookEnded ? 'collapse' : 'visible' }} />
                        </IconButton>
                        <div >
                            {userData && <BookSettings onFontSizeChange={onFontSizeChange}
                                bookData={bookData}
                                onThemeChange={handleThemeChange}
                                showBookSettings={showBookSettings}
                                onClose={onSettingsClose}
                                textBookGenerated={textBookGenerated}
                                isContentBookLoading={isContentBookLoading} />}
                        </div>
                    </Box>
                    {/* Vertical Divider */}
                    <Divider orientation="vertical" flexItem sx={{ mr: 2, ml: 2, mt: 2, height: '30px' }} />
                    <Box sx={{ flexGrow: 0 }}>
                        {/* <Tooltip title="Open settings"> */}
                        {/* <Link to="/books" style={{ textDecoration: 'none', color: 'inherit' }}> */}
                        <IconButton onClick={() => navigate(-1)} sx={{ p: 0, color: "#96a3ac" }}>
                            <HighlightOffIcon className='settingsIcon' style={{ width: '30px', height: '30px' }} />
                        </IconButton>
                        {/* </Link> */}
                        {/* </Tooltip> */}
                        {/* <BookSettings/> */}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default EbookBar;