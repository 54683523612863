// ErrorHandler.js
let setError = null;

export const setErrorHandler = handler => {
  setError = handler;
};

export const triggerError = error => {
  if (setError) {
    setError(error);
  }
};
