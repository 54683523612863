import React, { useState } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import ReactFlagsSelect from "react-flags-select";

const TranslationLanguageSelectBox = ({onTranslationBookSelect, valid}) => {
  const [selected, setSelected] = useState("");
  
  const handleChange = (bookLanguage) => {
    setSelected(bookLanguage);
    onTranslationBookSelect(bookLanguage);
  };

  return (
    <div>
    <label style={{fontSize: '12px', color: '#00B2FF'}}>Translation Language</label>
    <ReactFlagsSelect
            selectButtonClassName="book-select-box"
            countries={["PL", "GB", "FR", "DE", "IT"]}
            customLabels={{ GB: "English", FR: "French", DE: "German", IT: "Italian", PL: "Polish" }}
            onSelect={handleChange}
            selected={selected}
            placeholder="Select Translation Language" />
        <FormHelperText sx={{ color: 'red', visibility: !valid ? 'visible' : 'hidden' }}>
            Please select translation language
        </FormHelperText>
  </div>
  );
};

export default TranslationLanguageSelectBox;
