import React, { useEffect, useState, useContext } from 'react';
import keycloakConfig from './Keycloak-config';
import axiosInstance from './AxiosConfig';
import { CircularProgress } from '@mui/material';

export const KeycloakContext = React.createContext();

export function KeycloakProvider({ children }) {
  const [keycloak, setKeycloak] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    keycloakConfig.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256'
    }).then(authenticated => {
      setKeycloak(keycloakConfig);
      setAuthenticated(authenticated);
      // Remove or comment out this block
      /*
      if (!authenticated) {
        keycloakConfig.login().catch(error => {
          console.error("Login failed:", error);
        });
      }
      */
    }).catch(error => {
      console.error("Error initializing Keycloak:", error);
    });
  }, []);

  // Update Axios when the token changes
  useEffect(() => {
    axiosInstance.defaults.headers.Authorization = `Bearer ${keycloakConfig.token}`;
  }, [keycloakConfig.token]);

  if (!keycloak) {
    return <div className="spinner-container-language">
    <CircularProgress sx={{ color: '#00B2FF' }} />
  </div>; // Return a loading component if needed
  }

  return (
    <KeycloakContext.Provider value={{ keycloak, authenticated }}>
      {children}
    </KeycloakContext.Provider>
  );
}
