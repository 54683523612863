import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './PageNotFound.css';
import MainAppBar from '../homePage/AppBar';
import FooterApp from '../../componentLanding/FooterApp';
import { useUser } from '../userContext/UserContext.js';
const PageNotFound = () => {
  const { userData, loading } = useUser();
  const handleHomeClick = () => {
    window.location.href = '/dashboard'; // Redirect and refresh the page
  };
 
  return (
    <>
    <MainAppBar userData={userData}/>
    <Container className="not-found-page-container d-flex flex-column justify-content-center align-items-center vh-100 text-center">
      <div className="background-overlay"></div> {/* Overlay div */}
      <Row>
        <Col>
          <h1 className="text-danger" style={{fontSize: '2.5em', fontWeight: 'bold', margin: '0.5em 0'}}>404</h1>
          <h2 style={{fontSize: '1.5em', fontWeight: 'bold', margin: '0.5em 0'}}>Page Not Found</h2>
          <p className="lead" style={{marginBottom: '25px'}}>
            Sorry, the page you're looking for doesn't exist or has been moved.
          </p>
          <Button variant="primary" className="not-found-button" onClick={handleHomeClick}>
            Go to Homepage
          </Button>
        </Col>
      </Row>
    </Container>
    <FooterApp />
    </>
  );
};

export default PageNotFound;
