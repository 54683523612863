import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import InputFileUpload from '../homePage/InputFileUpload';
import axios from '../../components/AxiosConfig';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import MainAppBar from '../homePage/AppBar';
import LanguageAutocomplete from '../languageAutocomplete/LanguageAutocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';  // Import the useMediaQuery hook
import { useNavigate } from 'react-router-dom';
import { gptLanguages } from '../../constants/global.js';
import PricingBoxModal from '../subscription/PircingBoxModal.js';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useUser } from '../userContext/UserContext.js';

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: '400px',
  margin: 'auto',
  borderRadius: '20px',
  boxShadow: theme.shadows[5],
  backgroundColor: 'white',
}));

const UploadBook = ({ onBookUploaded, isOpen, onClose }) => {
  const { t } = useTranslation();
  const [bookFile, setBookFile] = useState(null);
  const [bookLanguage, setBookLanguage] = useState(null);
  const [bookName, setBookName] = useState("");
  const [bookDescription, setBookDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isBookLanguageValid, setIsBookLanguageValid] = useState(true);
  const [isBookNameValid, setIsBookNameValid] = useState(true);
  const [isBookDescriptionValid, setIsBookDescriptionValid] = useState(true);
  const [isFileValid, setIsFileValid] = useState(true);
  const [showPremium, setShowPremium] = useState(false);
  const { userData, loading } = useUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Define a media query for mobile devices
  const navigate = useNavigate();

  const handleAddBook = () => {
    const formData = new FormData();
    if (bookFile === null || bookLanguage === null || bookName === '') {
      setIsFileValid(bookFile !== null);
      setIsBookLanguageValid(bookLanguage !== null);
      setIsBookNameValid(bookName !== '');
      return;
    }

    formData.append('file', bookFile);
    formData.append('bookLanguage', bookLanguage.name);
    formData.append('bookName', bookName);
    formData.append('bookDescription', bookDescription);
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/api/Book`, formData)
      .then(response => {
        setIsLoading(false);
        navigate(`/reader/${response.data}`);
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          setShowPremium(true);
        }
        setIsLoading(false);
      });
  };

  const onPremiumClose = () => {
    setShowPremium(false);
  }

  const handleFile = (file) => {
    setBookFile(file);
    setIsFileValid(true);
    if (file !== null) {
      setBookName(file.name.split(".")[0]);
    }
  };

  const handleBookLanguageSelect = (event, newValue) => {
    setBookLanguage(newValue);
    setIsBookLanguageValid(true);
  };

  const bookNameChange = (event) => {
    setBookName(event.target.value);
    if (event.target.value.trim() !== '') {
      setIsBookNameValid(true);
    }
  };

  const bookDescriptionChange = (event) => {
    setBookDescription(event.target.value);
    if (event.target.value.trim() !== '') {
      setIsBookDescriptionValid(true);
    }
  };

  return (
    <div>
      <MainAppBar userData={userData}/>
      <div className='upload-container'>
        <Typography sx={{ fontWeight: 'bold', marginBottom: 1, marginTop: 2, fontSize: '1.8rem' }}>
          {t('uploadBookOrArticle')}
        </Typography>
        <InputFileUpload onFileSelect={handleFile} valid={isFileValid} />
        {isLoading && (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backdropFilter: 'blur(5px)',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: 9999,
    }}
  >
    <Stack spacing={1} alignItems="center">
      <span>{t('waiting')}</span>
      <CircularProgress size={30}/>
    </Stack>
  </Box>
)}

        <Box sx={{ marginTop: 0 }}>
          <label style={{fontSize: '16px', color: '#00B2FF', marginBottom: '-5px'}}>{t('bookName')}</label>
          <TextField
            sx={{ width: '100%', marginTop: 1, '& .MuiInputBase-root': { height: '50px' } }}
            id="outlined-basic"
            label={t('bookName')}
            size="normal"
            value={bookName}
            onChange={bookNameChange}
          />
          <FormHelperText sx={{ color: 'red', visibility: !isBookNameValid ? 'visible' : 'hidden', fontSize: '13px' }}>
            {t('fillBookName')}
          </FormHelperText>
        </Box>
        <label style={{fontSize: '16px', color: '#00B2FF'}}>{t('bookLanguage')}</label>
        <LanguageAutocomplete
          languages={gptLanguages}
          selectedLanguage={bookLanguage}
          onLanguageChange={handleBookLanguageSelect}
          isValid={isBookLanguageValid}
        />
        <Box sx={{ marginTop: 1 }}>
          <label style={{fontSize: '16px', color: '#00B2FF', marginBottom: '-5px'}}>{t('bookDescription')}</label>
          <TextField
            sx={{ width: '100%', marginTop: 1, '& .MuiInputBase-root': { height: '100px' } }}
            id="outlined-multiline-static"
            label={t('bookDescription')}
            multiline
            rows={4}
            value={bookDescription}
            onChange={bookDescriptionChange}
          />
          <FormHelperText sx={{ color: 'red', visibility: !isBookDescriptionValid ? 'visible' : 'hidden', fontSize: '13px' }}>
            {t('fillBookDescription')}
          </FormHelperText>
        </Box>
        <Box sx={{ textAlign: isMobile ? 'center' : 'right', marginTop: 0 }}>  {/* Align text based on the device */}
        <Button 
          onClick={handleAddBook} 
          variant="outlined" 
          sx={{
            fontWeight: 'bold', 
            border: '1px solid #00B2FF', 
            backgroundColor: '#00B2FF', 
            color: 'white',
            width: isMobile ? '100%' : 'auto',  // Stretch to 100% width on mobile
            fontSize: isMobile ? '1.2rem' : '1rem',  // Increase font size on mobile
            padding: isMobile ? '12px' : '10px 20px',  // Increase padding on mobile
            '&:hover': {
              backgroundColor: '#00B2FF',  // Keep the background color the same
              borderColor: '#00B2FF',  // Keep the border color the same
            },
          }}
        >
          {t('addBook')}
        </Button>
        </Box>
      </div>
      {showPremium && <PricingBoxModal visibility={showPremium} onPremiumClose={onPremiumClose}/>}
    </div>
  );
};

export default UploadBook;
