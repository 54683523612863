import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import InputFileUpload from './InputFileUpload';
import TranslationLanguageSelectBox from './TranslationLanguageSelectBox';
import BookLanguageSelectBox from './BookLanguageSelectBox';
import axios from '../../components/AxiosConfig';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    width: '80%',
    maxWidth: '400px', 
  },
}));

const UploadBookDialog = ({onBookUpladed, isOpen, onClose}) => {
  const [open, setOpen] = useState(false);
  const [bookFile, setBookFile] = useState(null);
  const [bookLanguage, setBookLanguage] = useState("");
  const [translationLanguage, setTranslationLanguage] = useState("");
  const [bookName, setBookName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isBookLanguageValid, setIsBookLanguageValid] = useState(true);
  const [isTranslationLanguageValid, setIsTranslationLanguageValid] = useState(true);
  const [isBookNameValid, setIsBookNameValid] = useState(true);
  const [isFileValid, setIsFileValid] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    if(isOpen != undefined && isOpen){
      setOpen(true);
    }
}, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    setIsTranslationLanguageValid(true);
    setIsBookLanguageValid(true);
    setIsFileValid(true);
    onClose();
  };

  const handleAddBook = () => {
    const formData = new FormData();

    if(bookFile === null || bookLanguage === '' || translationLanguage === '' || bookName === '')
    {
        setIsFileValid(bookFile !== null);
        setIsBookLanguageValid(bookLanguage !== '');
        setIsTranslationLanguageValid(translationLanguage !== '');
        setIsBookNameValid(bookName !== '')
        return;
    }

    formData.append('file', bookFile);
    formData.append('bookLanguage', bookLanguage);
    formData.append('translationLanguage', translationLanguage);
    formData.append('bookName', bookName);

    setIsLoading(true);
      axios.post(`${process.env.REACT_APP_API_URL}/api/Book`, formData)
    .then(response => {
        setIsLoading(false);
        setOpen(false);
        onBookUpladed({bookName: bookName, bookUrl: '', id: response.data});
    })
    .catch(error => {
    });
  };

  const handleFile = (file) => {
    setBookFile(file);
    setIsFileValid(true);
    if(file !== null){
        setBookName(file.name.split(".")[0]);
    }
  }

  const handleBookLanguageSelect = (bookLanguage) => {
    setBookLanguage(bookLanguage);
    setIsBookLanguageValid(true);
  }

  const handleTranslationLanguageSelect = (translationLanguage) => {
    setTranslationLanguage(translationLanguage);
    setIsTranslationLanguageValid(true);
  }

  const bookNameChange = (event) => {
    setBookName(event.target.value);
    if(event.target.value.trim() !== ''){
        setIsBookNameValid(true)
    }
  };

  return (
    <React.Fragment>
      {/* <button className="upload-book" onClick={handleClickOpen}>Add your own book</button> */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{"& .MuiDialog-paper": {
          borderRadius: "20px",
        }}}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }} id="customized-dialog-title" onClick={handleClose}>
          Add book
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers >
        <Typography gutterBottom>
        
          <InputFileUpload onFileSelect={handleFile} valid={isFileValid}/>
          {isLoading && <Box sx={{ position: 'absolute', left: '45%', top: '45%' }}>
            <CircularProgress />
          </Box>}
          <Box Box >
          <TextField sx={{ width: '100%', marginTop: 1, '& .MuiInputBase-root': { height: '50px' }}} id="outlined-basic" label="Book name" size="normal" value={bookName} onChange={bookNameChange}/>
          <FormHelperText sx={{ color: 'red', visibility: !isBookNameValid ? 'visible' : 'hidden' }}>
          Please fill a book name
        </FormHelperText>
          </Box>
          <BookLanguageSelectBox onBookLanguageSelect={handleBookLanguageSelect} valid={isBookLanguageValid}/>
          <TranslationLanguageSelectBox onTranslationBookSelect={handleTranslationLanguageSelect} valid={isTranslationLanguageValid}/>
        </Typography>
        </DialogContent>
        <DialogActions>
         
          <Button onClick={handleAddBook} variant="outlined" sx={{fontWeight: 'bold', border: '1px solid #00B2FF', backgroundColor: '#00B2FF', color: 'white'}}>Add book</Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default UploadBookDialog;