import { useState, useEffect, useRef } from 'react';
import * as signalR from "@microsoft/signalr";

const useSignalRConnectionTooltipTranslation = (translationId, bookId) => {
  const [tooltipTranslation, setTooltipTranslation] = useState('');
  const connectionRef = useRef(null);

  // Setup the SignalR connection once when the component mounts
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/api/signalrtransation`)
      .withAutomaticReconnect()
      .build();

    // Handle reconnection: resubscribe when reconnected
    connection.onreconnected(() => {
      console.log('Reconnected to SignalR hub.');
      subscribeToTranslations();
    });

    // Handle disconnection
    connection.onclose(() => {
      console.warn('SignalR connection closed.');
    });

    connection.start()
      .then(() => {
        console.log('SignalR connection established.');
        connectionRef.current = connection;
        subscribeToTranslations(); // Subscribe initially
      })
      .catch(error => {
        console.error('SignalR connection failed to start:', error);
      });

    return () => {
      if (connectionRef.current) {
        connectionRef.current.stop();
      }
    };
  }, []); // Empty dependency array ensures this runs only once

  // Function to handle subscription
  const subscribeToTranslations = () => {
    if (connectionRef.current) {
      connectionRef.current.invoke('Subscribe', `${bookId}-translation`)
        .then(() => {
          console.log('Subscription successful!');
        })
        .catch(error => {
          console.error('Subscription failed:', error);
        });
    }
  };

  // Listen to translation messages
  useEffect(() => {
    const connection = connectionRef.current;
    if (connection) {
      const handler = (newMessage) => {
        const { id, message } = newMessage;
        if (id === translationId) {
          setTooltipTranslation(prev => [...prev, message]);
        }
      };

      connection.on('translation', handler);

      return () => {
        connection.off('translation', handler);
      };
    }
  }, [translationId]); // This depends on translationId, but doesn't recreate the connection

  const clearTooltipTranslation = () => {
    setTooltipTranslation('');
  };

  return { tooltipTranslation, clearTooltipTranslation };
};

export default useSignalRConnectionTooltipTranslation;
