import React, { useState, useEffect } from 'react';
import MainAppBar from './../homePage/AppBar';
import { useNavigate } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'search-elastic';
import SearchIntermediate from '../../icons/SearchIntermediate';
import defaultImage from '../../images/book-default-min.png';
import axios from '../../components/AxiosConfig';
import SearchAdvanced from '../../icons/SearchAdvanced';
import SearchBasic from '../../icons/SearchBasic';
import { Link } from 'react-router-dom';
import RecentBooksGrid from '../homePage/RecentBooksGrid';
import '../homePage/BookShelf.css';
import UploadBookDialog from '../homePage/UploadBookDialog';
import SucceedBookUploadDialog from '../homePage/SucceedBookUploadDialog';
import { useMediaQuery } from '@material-ui/core';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useUser } from '../userContext/UserContext.js';
import LearningBooksGrid from './LearningBooksGrid.js';
import LearningBigIcon from '../../icons/LearningBigIcon.js';

const LearningPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchResults, setSearchResults] = useState([]);
    const [isUploadOpen, setIsUploadOpen] = useState(null);
    const [uploudedBook, setUploudedBook] = useState(null);
    const isMobile = useMediaQuery('(max-width:900px)');
    const { userData, loading } = useUser();

    // Redirect to subscription page if the subscription is inactive
    useEffect(() => {
        if (!loading && userData?.isSubscriptionActive === false) {
            navigate('/subscription/premium');
        }
    }, [loading, userData, navigate]);

    const handleOnUploadText = () => {
        navigate(`/uploadBook`);
    };

    const handleOnSearch = async (query) => {
        try {
            if (query === '') {
                setSearchResults([]);
                return;
            }
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/fulltextsearch/library?search=${query}&skip=0&limit=10`);
            const formattedResults = response.data.map(book => ({
                author: book.authors.length > 0 ? book.authors[0].name : t('unknownAuthor'),
                name: book.title,
                genreInner: book.genreInner,
                languageLevel: book.languageLevel,
                url: book.url,
                genreMain: book.genreMain,
                id: book.id,
                imageUrl: book.imageUrl
            }));
            setSearchResults(formattedResults);
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    const handleOnSelect = (item) => {
        navigate(`/bookdetails/${item.id}`);
    };

    const handleOnClear = () => {};

    const handleBookUplouded = (book) => {
        setUploudedBook(book);
    };

    const handleOnClose = () => {
        setUploudedBook(null);
        setIsUploadOpen(false);
    };

    const handleDialogClose = () => {
        setIsUploadOpen(false);
    };

    const formatResult = (item) => {
        return (
            <Link to={`/bookdetails/${item.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className="result-wrapper">
                    <img style={{ height: '70px', width: '70px', marginRight: '1rem' }} src={item.imageUrl || defaultImage} />
                    <div className="result-details">
                        <span className="result-span" style={{ display: 'block', fontSize: '18px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</span>
                        <span className="result-span" style={{ display: 'block', color: '#96a3ac', fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.author}</span>
                        <span style={{ display: 'flex', fontSize: '12px' }}>{t('languageLevel')}:
                            {item.languageLevel === 'basic' && <span style={{ display: 'inline-flex' }}><SearchBasic /></span>}
                            {item.languageLevel === 'intermediate' && <span style={{ display: 'inline-flex' }}><SearchIntermediate /></span>}
                            {item.languageLevel === 'advanced' && <span style={{ display: 'inline-flex' }}><SearchAdvanced /></span>}
                        </span>
                        <span className="result-span" style={{ display: 'block', fontSize: '11px' }}>Genre:
                            <span style={{ background: '#7853ea', color: 'white', marginLeft: '5px', borderRadius: '5px' }}>&nbsp;&nbsp;{item.genreMain}&nbsp;&nbsp;</span>
                            <span style={{ background: '#7853ea', color: 'white', marginLeft: '5px', borderRadius: '5px' }}>&nbsp;&nbsp;{item.genreInner}&nbsp;&nbsp;</span>
                        </span>
                    </div>
                </div>
            </Link>
        );
    };

    return (
        <section>
            <MainAppBar onUploadText={handleOnUploadText} userData={userData} />
            <div className='book-shelf'>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    fontSize: '3vh',
                    marginBottom: '15px',
                    marginTop: '20px'
                }}>
                    <LearningBigIcon />
                    <span style={{ marginLeft: '10px' }}>Learning</span>
                </div>
                <div className="books">
                    <LearningBooksGrid />
                </div>
            </div>
        </section>
    );
};

export default LearningPage;
