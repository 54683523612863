import React from 'react';
import PricingBox from './PricingBox'; // Ensure you import the PricingBox component
import "./pricing.css";
const Subscription = () => {
  return (
    <>
      <div>
        <PricingBox />    
      </div>
    </>
  );
};

export default Subscription;
