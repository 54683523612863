import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './SuccessPage.css';

const SuccessPage = () => {
  const handleHomeClick = () => {
    window.location.href = '/books';
  };

  return (
    <Container className="success-page-container d-flex flex-column justify-content-center align-items-center vh-100 text-center">
      <div className="background-overlay"></div> {/* Overlay div */}
      <Row>
        <Col>
          <h1 className="text-success" style={{fontSize: '2em', fontWeight: 'bold', margin: '0.5em 0'}}>Subscription Successful!</h1>
          <p className="lead" style={{marginBottom: '25px'}}>
            Thank you for subscribing. Your payment has been successfully processed.
          </p>
          <Button variant="primary" className="success-button" onClick={handleHomeClick}>
            Go to Homepage
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SuccessPage;
