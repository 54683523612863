import React from 'react';

const LearningIcon = () => (
    <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="37px"
    height="37px"
    viewBox="0 0 122.88 88.08"
    style={{ enableBackground: "new 0 0 122.88 88.08" }}
    xmlSpace="preserve"
  >
    <style type="text/css">{`
      .st0 { fill-rule:evenodd; clip-rule:evenodd; }
    `}</style>
    <g>
      <path
        className="st0"
        d="M83.1,33.02l-0.3-11.21l-18.42,5.32c-1.66,0.36-3.29,0.54-4.9,0.54c-1.72,0.01-3.41-0.18-5.08-0.54l-17.6-5.38
          v11.37c0.5,5.9,17.44,7.71,22.64,7.96c4.17,0.2,20.63-1.97,22.79-5.37C82.7,34.97,82.99,34.07,83.1,33.02L83.1,33.02z 
          M2.54,83.01 h12.59c-0.93-0.24-1.63-1.1-1.63-2.1v-55.7c0-1.19,0.98-2.17,2.17-2.17h9.72c-0.31,1.11-0.49,2.24-0.51,3.4h-7.66l0,0v53.85
          h88.19V26.44h-7.43c-0.02-1.16-0.19-2.29-0.5-3.4h9.17c1.19,0,2.17,0.98,2.17,2.17V80.9c0,1.01-0.69,1.86-1.63,2.1h13.16 
          c1.4,0,2.54,1.14,2.54,2.54l0,0c0,1.4-1.14,2.54-2.54,2.54H2.54c-1.4,0-2.54-1.14-2.54-2.54l0,0C0,84.15,1.14,83.01,2.54,83.01 
          L2.54,83.01L2.54,83.01z M27.14,70.69c-0.81,0-1.47-0.66-1.47-1.47s0.66-1.47,1.47-1.47h57.38c0.81,0,1.47,0.66,1.47,1.47 
          s-0.66,1.47-1.47,1.47H27.14L27.14,70.69z M27.14,61.92c-0.81,0-1.47-0.66-1.47-1.47s0.66-1.47,1.47-1.47h43.93 
          c0.81,0,1.47,0.66,1.47,1.47s-0.66,1.47-1.47,1.47H27.14L27.14,61.92z M27.14,53.15c-0.81,0-1.47-0.66-1.47-1.47 
          s0.66-1.47,1.47-1.47h57.84c0.81,0,1.47,0.66,1.47,1.47s-0.66,1.47-1.47,1.47H27.14L27.14,53.15z M57.87,83.96h7.14 
          c0.67,0,1.22,0.55,1.22,1.22l0,0c0,0.67-0.55,1.22-1.22,1.22h-7.14c-0.67,0-1.22-0.55-1.22-1.22l0,0 
          C56.65,84.5,57.2,83.96,57.87,83.96L57.87,83.96L57.87,83.96z M90.57,13.74v13.7H91c0.29,0,0.54,0.24,0.54,0.54v3.68 
          c0,0.29-0.24,0.54-0.54,0.54h-0.44v1.29c0.55,0.1,0.97,0.59,0.97,1.17v0c0,0.65-0.54,1.19-1.19,1.19h-2.13 
          c-0.65,0-1.19-0.53-1.19-1.19v0c0-0.58,0.42-1.06,0.97-1.17v-1.29h-0.43c-0.29,0-0.54-0.24-0.54-0.54v-3.68 
          c0-0.3,0.24-0.54,0.54-0.54H88v-12.9l-22.42,6.97c-4.06,0.97-8.12,1.03-12.18,0l-24.74-7.39l-2.24-0.67 
          c-2.79-1.14-2.09-3.81,0.5-4.43l27.99-8.36c2.89-0.82,5.77-0.95,8.66,0l27.48,8.26c2.99,0.73,3.58,3.49,0.1,4.63L90.57,13.74 
          L90.57,13.74z"
      />
    </g>
  </svg>
);

export default LearningIcon;
