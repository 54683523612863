import * as React from "react";

function B1Icon() {
  return (
    <svg width="30" height="25" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="25" fill="#00B2FF" rx="12.5" ry="12.5"/>
      <text 
        x="50%" 
        y="50%" 
        fill="white" 
        font-size="13" 
        font-weight="bold" 
        font-family='"Source Code Pro", monospace' 
        dy=".4em" 
        text-anchor="middle"
      >
        B1
      </text>
    </svg>
  );
}

export default B1Icon;
