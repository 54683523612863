import React, { useEffect, useState } from "react";
import axios from '../../components/AxiosConfig';
import { useNavigate, useLocation } from "react-router-dom";
import PremiumBox from "./PremiumBox";
import PremiumTable from "./PremiumTable";
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

const PremiumPricingBox = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [premium, setPremium] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingButtons, setLoadingButtons] = useState({});

  // Reset loadingButtons when the page is shown again
  useEffect(() => {
    const handlePageShow = () => {
      setLoadingButtons({});
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/subscription/premium`)
      .then(response => {
        setPremium(response.data);
        const initialButtonsState = {};
        response.data.plans.forEach(plan => {
          initialButtonsState[plan.month] = false; // Set each plan button to false initially
        });
        setLoadingButtons(initialButtonsState); // Update state with the initial button states
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setError('There was an error fetching the data. Please try again later.');
        setLoading(false);
      });
  }, [location]); 

  const handlePremiumClick = (planId) => {
    setLoadingButtons(prevState => ({ ...prevState, [planId]: true }));

    axios.post(`${process.env.REACT_APP_API_URL}/api/subscription/pay`, { month: planId })
      .then(response => {
        const url = response.data; // Assuming the response contains the URL in this field
        window.location.href = url;
      })
      .catch(error => {
        console.error('There was an error processing the payment!', error);
        setError('There was an error processing the payment. Please try again later.');
        setLoadingButtons(prevState => ({ ...prevState, [planId]: false }));
      });
  };

  const handleBackClick = () => {
    navigate(-1);
  };
  
  if (loading) {
    return (
      <div className="loading-container text-center my-5">
        <Spinner animation="border" role="status" variant="primary">
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert variant="danger">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <div className="container" style={{background: '#f0fbff'}}>
      <div className="back-button-container">
        <button className="btn btn-back" onClick={handleBackClick}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#007bff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </div>
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h1 className="display-4 fw-normal">{premium.header.title} 🐼</h1>
        {/* <p className="fs-5 text-muted">{premium.header.text}</p> */}
      </div>
      <div className="row justify-content-center mb-3 text-center">
        {premium.plans.map((plan) => (
          <div key={plan.id} className="col-md-6 col-lg-4 mb-4">
            <PremiumBox
              price={plan.price}
              title={plan.title}
              btnClass={plan.btnClass}
              btnTitle={loadingButtons[plan.month] ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : plan.btnTitle}
              yearlyCost={plan.yearlyCost}
              originalYearlyCost={plan.originalYearlyCost}
              billedYearly={plan.billedYearly}
              saveAmount={plan.saveAmount}
              saveName={plan.saveName}
              yearlyCostName={plan.yearlyCostName}
              perMonth={plan.perMonth}
              feature={plan.featuresTable}
              onClick={() => handlePremiumClick(plan.month)}
              disabled={loadingButtons[plan.month]} // Disable the button if it is loading
            />
          </div>
        ))}
      </div>
      <div>
        <h1 style={{ textAlign: 'center', fontSize: '2.5rem', fontWeight: 'bold'}}>{premium.titleTable}</h1>
        <PremiumTable plans={premium.tableComparations} featuresName={premium.featuresName} />
      </div>
    </div>
  );
};

export default PremiumPricingBox;
