import React, { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './TranslationHistory.css'
import BarLoader from 'react-spinners/BarLoader';
import axios from '../../components/AxiosConfig';
import { styled } from '@mui/system';

const SPEAKER_ICON_COLOR = '#00B2FF';
const CustomTableCell = styled(TableCell)(({ theme }) => ({
  color: theme === 'dark' ? 'white' : 'black', // Set your desired color here
}));

const HistoryTable = ({translations, bookId, currentTheme}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const componentRef = useRef(null);
  const limit = 20;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/HistoryBook/translations/${bookId}/${page}/${limit}`);
        const data = response.data;
        setItems((prevItems) => [...prevItems, ...data]);
        setLoading(false);
      } catch (error) {
        //setFetchError(error);
      }
    };

    fetchData();
  }, [page]);
  const handleScroll = () => {
    const component = componentRef.current;
    if (loading == false && (component && component.clientHeight + component.scrollTop === component.scrollHeight)) {
      setPage((prevPage) => prevPage + 20);
    }
  };

  useEffect(() => {
    const component = componentRef.current;

    if (component) {
      component.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (component) {
        component.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if(translations.orginalText !== ''){
      const newTranslation = [translations, ...items];
      setItems(newTranslation);
    }
    
  }, [translations]);

  return (
    <div className='translationHistory' style={{ overflowY: 'auto', height:'100%'}} ref={componentRef}>
      <TableContainer component={Paper} style={{ backgroundColor: currentTheme === 'white' ? 'white' : (currentTheme === 'light' ? '#F0EEDF' : '#565656') }} sx={{color: 'white !important'}}>
      <Table aria-label="simple table" sx={{color: 'white !important'}}>
        <TableBody>
          {items.map((x) => (
            <TableRow
              key={x.index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <CustomTableCell theme={currentTheme} align="left" style={{fontSize: '1.0rem'}}>{x.orginalText} </CustomTableCell>
              <CustomTableCell theme={currentTheme} align="left" style={{fontWeight:'bold', color: '#00B2FF', fontSize:'1.1rem'}}>{x.targetText}</CustomTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
      {loading && <p style={{textAlign: '-webkit-center'}}><BarLoader color={SPEAKER_ICON_COLOR} /></p>}
    </div>
  );
};

export default HistoryTable;
