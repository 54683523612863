import React, { useState, useEffect } from 'react';
import "../percentageSettings/PercentageSettings.css";
import CloseIcon from '@material-ui/icons/Close';
import ArrowRightTopIcon from '../../icons/ArrowRightTopIcon';
import { useTranslation } from 'react-i18next';

const PercentageSettings = ({ showPercentageSettings, theme, onStartPageLocation, onFurthestPageLocation, onSpecificPageLocation, onClosePercentageSettings, pageIndexes, total, furthestLocation }) => {

  const [isVisible, setIsVisible] = useState('hidden');
  const { t } = useTranslation();

  useEffect(() => {
    if (showPercentageSettings) {
      setIsVisible('visible');
    } else {
      setIsVisible('hidden');
    }
  }, [showPercentageSettings]);

  const onClose = () => {
    setIsVisible('hidden');
    onClosePercentageSettings();
  };

  const startPage = () => {
    onStartPageLocation(0);
  };

  const furthestPage = () => {
    onFurthestPageLocation(furthestLocation);
  };

  const goToSpecificLocation = () => {
    onSpecificPageLocation(0);
  };

  return (
    <>
      {isVisible === 'visible' && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(1.5px)',
          zIndex: 999
        }} onClick={onClose} />
      )}
      <div className='percentageLimitContent' style={{
        visibility: isVisible,
        position: 'fixed',
        bottom: '5rem',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        maxWidth: '300px',
        boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
        backgroundColor: 'white',
        zIndex: 1000,
        background: theme === 'white' ? 'white' : (theme === 'light' ? '#DBD6C5' : '#565656')
      }}>
        <span className="closeLimitPopup" onClick={() => onClose(false)}>
          <CloseIcon style={{ width: '20px', height: '20px' }} />
        </span>
        <div className='percentageLimitContent__header' style={{ color: theme === 'dark' ? 'white' : 'black', marginBottom: '10px' }}>
          {t('location')} {pageIndexes.firstPageIndex} / {total}
        </div>
        <div className='percentageLimitContent__description' style={{ color: theme === 'dark' ? 'white' : 'black', display: 'flex', flexDirection: 'column', fontSize: '15px' }}>
          <span style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }} onClick={startPage}>
            {t('goToStart')} <ArrowRightTopIcon style={{ marginLeft: '12px' }} />
          </span>
          <span style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }} onClick={furthestPage}>
            {t('goToFurthestReadLocation')} <ArrowRightTopIcon style={{ marginLeft: '12px' }} />
          </span>
          <span style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }} onClick={goToSpecificLocation}>
            {t('goToSpecificLocation')} <ArrowRightTopIcon style={{ marginLeft: '12px' }} />
          </span>
        </div>
        <div className="arrow-down-location" style={{ borderTop: `10px solid ${theme === 'white' ? 'white' : (theme === 'light' ? '#DBD6C5' : '#565656')}` }}></div>
      </div>
    </>
  );
};

export default PercentageSettings;
