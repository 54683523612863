// DrawerContext.js
import React, { createContext, useState, useContext } from 'react';

const DrawerContext = createContext();

export const DrawerProvider = ({ children }) => {
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
  const [drawerCategoryPosition, setDrawerCategoryPosition] = useState(false); // Add state for the drawer position

  const handleOpenLeftDrawer = (t, isCategory = false) => {
    setLeftDrawerOpen(true);
    setDrawerCategoryPosition(isCategory); // Set the drawer position
  };

  const handleCloseLeftDrawer = () => {
    setLeftDrawerOpen(false);
  };

  return (
      <DrawerContext.Provider value={{ leftDrawerOpen, drawerCategoryPosition, handleOpenLeftDrawer, handleCloseLeftDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => useContext(DrawerContext);
