import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import './SucceedBookUploadDialog.css'
import defaultImage from '../../images/book-default-min.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    width: '80%',
    maxWidth: '400px', 
  },
}));

const SucceedBookUploadDialog = ({isOpen, uploadedBook, onClose}) => {

  const handleClose = () => {
    onClose();
  };

  const handleFile = (file) => {
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }} id="customized-dialog-title" onClick={handleClose}>
          Book added successfuly
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8, 
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers >
        <Typography>
        <a href={`/reader/${uploadedBook != null ? uploadedBook.id : ''}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <div >
          <div key="{book.id}" className="book-succeed-tile">
            <img src={defaultImage} alt="{book.title}" style={{width:'230px', height:'230px', borderRadius: '8px'}}/>
            <p className="book-name" style={{fontWeight: 'bold'}}>{uploadedBook != null ? uploadedBook.bookName : ''}</p>
            <button className="read-book">Read book</button>
          </div>   
        </div>
        </a>
        </Typography>
        </DialogContent>
        <DialogActions>
         
          <Button onClick={handleClose} variant="outlined" sx={{fontWeight: 'bold', border: '1px solid #00B2FF', color: '#00B2FF'}}>Close</Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default SucceedBookUploadDialog;