import React, { useContext } from 'react';
import { curve, heroBackground, robot } from "../assets";
import mobilePresentation from "../assets/mobile_presentation.gif";
import desktopPresentation from "../assets/desktop_presentation.gif";
import Button from "./Button";
import Section from "./Section";
import { BackgroundCircles, BottomLine, Gradient } from "./design/Hero";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { KeycloakContext } from '../components/KeycloakProvider';

const Curve = styled.div`
  width: 100%;
  height: 12px;
  background: linear-gradient(to right, #00f, #3399ff, #66ccff, #99ccff, #cceeff);
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  transform: rotate(0deg);

  @media (max-width: 768px) {
    height: 8px;
  }
`;

const GradientCurve = () => {
  return <Curve />;
};

const Hero = () => {
  const parallaxRef = useRef(null);
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 768); // Initialize based on screen size
  const navigate = useNavigate();
  const { keycloak } = useContext(KeycloakContext);
  const { t } = useTranslation();
  
  const handleRegister = () => {
    if (keycloak) {
      keycloak.login({ redirectUri: window.location.origin + '/books' }).catch(error => {
        console.error("Login failed:", error);
      });
    }
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  return (
    <Section
      className="pt-[12rem] -mt-[5.25rem]"
      crosses
      crossesOffset="lg:translate-y-[5.25rem]"
      customPaddings
      id="hero"
    >
      <div className="container relative" ref={parallaxRef}>
        <div className="relative z-1 max-w-[62rem] mx-auto text-center mb-[3.875rem] md:mb-20 lg:mb-[6.25rem] text-black">
          <h1 className="h1 mb-6">
            {t('exploreReadingWith')}{" "}
            <span className="inline-block relative">
              HappRead{" "}
              <GradientCurve />
            </span>
          </h1>
          <p className="body-1 max-w-3xl mx-auto mb-6 text-n-2 lg:mb-8 text-black" style={{fontSize: '1.3rem'}}>
            {t('unleashThe')}
          </p>
          <Button onClick={handleRegister} white >
            {t('startLearning')}
          </Button>
        </div>
        <div className="relative max-w-[23rem] mx-auto md:max-w-5xl xl:mb-24">
          <div className="relative z-1 p-0.5 rounded-2xl" style={{ backgroundColor: '#96E4FD' }}>
            <div className="relative bg-n-8 rounded-[1rem]">
              <div className={`h-[${isMobile ? '2rem' : '1.4rem'}] rounded-t-[0.9rem]`} style={{ backgroundColor: '#96E4FD' }} />
              <div className={`aspect-${isMobile ? '[40/40]' : '[33/40]'} md:aspect-[688/490] lg:aspect-[1024/490] rounded-b-[0.9rem] overflow-hidden`}>
                <img
                  src={isMobile ? mobilePresentation : desktopPresentation}
                  className="w-full h-full object-cover rounded-[1rem]"
                  alt="AI"
                />
              </div>
            </div>
          </div>
          <div className="absolute -top-[54%] left-1/2 w-[234%] -translate-x-1/2 md:-top-[46%] md:w-[138%] lg:-top-[104%]">
            <img
              src={heroBackground}
              className="w-full"
              width={1440}
              height={1800}
              alt="hero"
              style={{ opacity: 0.05 }}
            />
          </div>
          <BackgroundCircles />
        </div>
      </div>
      <BottomLine />
    </Section>
  );
};

export default Hero;
