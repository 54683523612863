import { useState, useEffect, useRef } from 'react';
import * as signalR from "@microsoft/signalr";

const useSignalRConnectionWordContextSentence = (wordContextTranslationId, bookId) => {
  const [wordContextSentence, setWordContextSentence] = useState('');
  const connectionRef = useRef(null);

  useEffect(() => {
    // Initialize connection only once
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/api/signalrtransation`)
      .withAutomaticReconnect()
      .build();

    // Handle reconnection: resubscribe when reconnected
    connection.onreconnected(() => {
      console.log('Reconnected to SignalR hub.');
      subscribeToWordContextTranslation();
    });

    // Handle disconnection
    connection.onclose(() => {
      console.warn('SignalR connection closed.');
    });

    connection.start()
      .then(() => {
        console.log('SignalR connection established.');
        connectionRef.current = connection;
        subscribeToWordContextTranslation(); // Subscribe initially
      })
      .catch(error => {
        console.error('SignalR connection failed to start:', error);
      });

    return () => {
      if (connectionRef.current) {
        connectionRef.current.stop();
      }
    };
  }, []); // Empty dependency array ensures this runs only once

  // Function to handle subscription
  const subscribeToWordContextTranslation = () => {
    if (connectionRef.current) {
      connectionRef.current.invoke('Subscribe', `${bookId}-wordcontexttranslation`)
        .then(() => {
          console.log('Subscription successful!');
        })
        .catch(error => {
          console.error('Subscription failed:', error);
        });
    }
  };

  useEffect(() => {
    const connection = connectionRef.current;
    if (!connection) return;

    clearWordContextSentence();

    const handler = (newMessage) => {
      const { id, message } = newMessage;
      if (id === wordContextTranslationId) {
        setWordContextSentence(prev => [...prev, message]);
      }
    };

    connection.on('wordcontexttranslation', handler);

    return () => {
      connection.off('wordcontexttranslation', handler);
    };
  }, [wordContextTranslationId]); // Now this effect handles only updates to wordContextTranslationId

  const clearWordContextSentence = () => {
    setWordContextSentence('');
  };

  const onSetWordContextSentence = (word) => {
    setWordContextSentence(word);
  };

  return { wordContextSentence, onSetWordContextSentence, clearWordContextSentence };
};

export default useSignalRConnectionWordContextSentence;
