import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const TitleBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
  width: '100%',
});

const BookTitle = styled('label')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: '1.2rem',
  marginRight: '10px',
  flexGrow: 1,
  marginBottom: '0' // This line reduces the space between the file name and the button below it.
});

export default function InputFileUpload({ onFileSelect, valid }) {
  const [bookTitle, setBookTitle] = useState("");
  const { t } = useTranslation();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && onFileSelect) {
      setBookTitle(file.name);
      onFileSelect(file);
    }
  };
  
  const onBookRemove = () => {
    setBookTitle("");
    onFileSelect(null);
  };

  return (
    <Box>
      <Box>
        <span style={{ fontSize: '1.2rem' }}>{t('supported')} </span>
        <span style={{ fontSize: '1.2rem' }}>{t('extensions')}: </span>
        <span style={{ background: '#7853ea', color: 'white', marginLeft: '5px', borderRadius: '5px' }}>&nbsp;&nbsp;.pdf&nbsp;&nbsp;</span>
        <span style={{ background: '#7853ea', color: 'white', marginLeft: '5px', borderRadius: '5px' }}>&nbsp;&nbsp;.mobi&nbsp;&nbsp;</span>
        <span style={{ background: '#7853ea', color: 'white', marginLeft: '5px', borderRadius: '5px' }}>&nbsp;&nbsp;.epub&nbsp;&nbsp;</span>
        <span style={{ background: '#7853ea', color: 'white', marginLeft: '5px', borderRadius: '5px' }}>&nbsp;&nbsp;.docx&nbsp;&nbsp;</span>
        <span style={{ background: '#7853ea', color: 'white', marginLeft: '5px', borderRadius: '5px' }}>&nbsp;&nbsp;.doc&nbsp;&nbsp;</span>
        <span style={{ background: '#7853ea', color: 'white', marginLeft: '5px', borderRadius: '5px' }}>&nbsp;&nbsp;.txt&nbsp;&nbsp;</span>
      </Box>
      <Box>
        <span style={{ marginTop: '10px', marginBottom: '10px', display: 'block', color: '#96a3ac' }}>{t('uploadedBookAvailableOnlyForYou')}</span>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', sm: 'flex-start' }, '@media (max-width:600px)': { width: '100%' } }}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          sx={{ bgcolor: '#00B2FF', '&:hover': { bgcolor: '#00B2FF' }, width: { xs: '100%', sm: 'auto' } }} 
        >
          {t('chooseFile')}
          <VisuallyHiddenInput 
            type="file" 
            onChange={handleFileChange}
            accept=".pdf,.mobi,.epub,.docx,.doc,.txt" 
          />
        </Button>
      </Box>
      {bookTitle && (
        <TitleBox>
          <BookTitle>
            {bookTitle}
          </BookTitle>
          <CloseIcon onClick={onBookRemove} style={{ color: '#00B2FF', cursor: 'pointer', marginLeft: '-10px' }} />
        </TitleBox>
      )}
      <FormHelperText sx={{ color: 'red', visibility: !valid ? 'visible' : 'hidden', fontSize: '13px' }}>
        {t('pleaseUploadBook')}
      </FormHelperText>
    </Box>
  );
}
