import ButtonGradient from "../assets/svg/ButtonGradient";
import Benefits from "./Benefits";
import Collaboration from "./Collaboration";
import Footer from "./Footer";
import Header from "./Header";
import Hero from "./Hero";
import Pricing from "./Pricing";
import Roadmap from "./Roadmap";
import Services from "./Services";
import './LandingPage.css';

const LandingPage = () => {
  return (
    <>
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden tw-body">
        <Header />
        <Hero />
        {/* <Collaboration /> */}
        <Services />
        <Benefits />

        <Pricing />
        {/* <Roadmap /> */}
        <Footer />
      </div>

      <ButtonGradient />
    </>
  );
};

export default LandingPage;
