// ErrorBoundary.js
import React from 'react';
import axios from '../../components/AxiosConfig';
import keycloakConfig from '../Keycloak-config';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  async componentDidCatch(error, info) {
    // Prepare error details
    const profile = await keycloakConfig.loadUserProfile();
    const errorDetails = {
      error: error.toString(),
      componentStack: info.componentStack,
      url: window.location.href,
      userAgent: navigator.userAgent,
      email: profile?.email
      // Include any other relevant info, like user ID if available
    };

    // Send error details to your backend using Axios
    axios.post(`${process.env.REACT_APP_API_URL}/api/errorFront`, errorDetails)
      .catch((err) => {
        console.error('Failed to send error to backend:', err);
      });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
