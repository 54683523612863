import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaCheckCircle } from 'react-icons/fa';
import axios from '../AxiosConfig';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import './Page.css';
import { useTranslation } from 'react-i18next';

const ChangeInterfaceLanguage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [initialLanguage, setInitialLanguage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { t, i18n } = useTranslation();

  const languages = [
    ['Polski', 'English', 'Русский'],
    ['Español', 'Português', 'Türkçe'],
    ['Français', 'Italiano', '한국어'],
    ['Deutsch', '日本語', 'Magyar'],
    ['Čeština', 'Slovenčina', 'Suomi'],
    ['Nederlands','Dansk', 'Ελληνικά',],
    ['Norsk', 'Svenska']
  ];

  const languageShortcuts = {
    'Polski': 'pl',
    'English': 'en',
    'Русский': 'ru',
    'Español': 'es',
    'Português': 'pt',
    'Türkçe': 'tr',
    'Français': 'fr',
    'Italiano': 'it',
    '한국어': 'ko',
    'Deutsch': 'de',
    '日本語': 'ja',
    'Magyar': 'hu',
    'Čeština': 'cs',
    'Slovenčina': 'sk',
    'Nederlands': 'nl',
    'Ελληνικά': 'el',
    'Svenska': 'sv',
    'Suomi': 'fi',
    'Dansk': 'da',
    'Norsk': 'no',
  };

  const languageCodes = {
    'Polski': 'Polish',
    'English': 'English',
    'Русский': 'Russian',
    'Español': 'Spanish',
    'Português': 'Portuguese',
    'Türkçe': 'Turkish',
    'Français': 'French',
    'Italiano': 'Italian', 
    '한국어': 'Korean', 
    'Deutsch': 'German',
    '日本語': 'Japanese',
    'Magyar': 'Hungarian',
    'Čeština': 'Czech',
    'Slovenčina': 'Slovak',
    'Nederlands': 'Dutch',
    'Ελληνικά': 'Greek',
    'Svenska': 'Swedish',
    'Suomi': 'Finnish',
    'Dansk': 'Danish', 
    'Norsk': 'Norwegian',
  };
  

  useEffect(() => {
    const fetchCurrentInterfaceLanguage = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`);
        if (response.status === 200 && response.data.interfaceLanguage) {
          const interfaceLanguage = response.data.interfaceLanguage;
          const language = Object.keys(languageCodes).find(key => languageCodes[key] === interfaceLanguage);
          setSelectedLanguage(language);
          setInitialLanguage(language);
        }
      } catch (error) {
        console.error('Error fetching current interfaceLanguage:', error);
      }
      setIsLoading(false);
    };

    fetchCurrentInterfaceLanguage();
  }, []);

  const saveLanguage = async () => {
    setIsSaving(true);
    const interfaceLanguage = languageCodes[selectedLanguage];
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/interface`, { interfaceLanguage }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setInitialLanguage(selectedLanguage);
        i18n.changeLanguage(languageShortcuts[selectedLanguage]);
        console.log('Language saved successfully');
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000); // Hide the alert after 3 seconds
      } else {
        console.error('Failed to save language');
      }
    } catch (error) {
      console.error('Error saving language:', error);
    }
    setIsSaving(false);
  };

  const handleBackClick = () => {
    window.location.href = '/books';
  };

  return (
    <div className="page-container">
      <div className="header">
        <button className="back-button" onClick={handleBackClick}><FaArrowLeft /></button>
        <h1 style={{ color: '#333', fontSize: '1.5em', margin: '0', borderBottom: '1px solid #ddd'}}>
          {t('changeInterfaceLanguage')}
        </h1>
      </div>
      {isLoading ? (
        <div className="spinner-container">
          <Spinner as="span" className="modal-confirm-button-spinner" animation="border" role="status" aria-hidden="true" />
        </div>
      ) : (
        <>
          {showAlert && (
            <div className="bottom-alert">
              <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                {t('languageUpdate')}
              </Alert>
            </div>
          )}
          <div className="languages">
            {languages.map((column, index) => (
              <div key={index} className="language-column">
                {column.map((language, idx) => (
                  <div
                    key={idx}
                    className={`language ${selectedLanguage === language ? 'selected' : ''}`}
                    onClick={() => setSelectedLanguage(language)}
                  >
                    <span className="language-text">{language}</span>
                    <span className="checkmark-container">
                      {selectedLanguage === language && (
                        <FaCheckCircle className="checkmark" />
                      )}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <button
            className="submit-button"
            style={{
              backgroundColor: selectedLanguage === initialLanguage ? 'gray' : '#00B2FF',
              width: '50%'
            }}
            onClick={saveLanguage}
            disabled={selectedLanguage === initialLanguage || isSaving}
          >
            {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : t('saveChanges')}
          </button>
        </>
      )}
    </div>
  );
};

export default ChangeInterfaceLanguage;
