import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box, InputAdornment } from '@mui/material';
import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';

const BookSettingsLanguageAutocomplete = ({ languages, selectedLanguage, onLanguageChange, theme, isValid = true }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();
  return (
    <Autocomplete
      options={languages}
      getOptionLabel={(option) => option.name}
      value={selectedLanguage}
      onChange={onLanguageChange}
      inputValue={searchTerm}
      onInputChange={(event, newInputValue) => setSearchTerm(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          placeholder={t('selectLanguage')}
          InputProps={{
            ...params.InputProps,
            startAdornment: selectedLanguage && (
              <InputAdornment position="start">
                <Flag code={selectedLanguage.flag} style={{ width: '20px', marginLeft: '10px' }} />
              </InputAdornment>
            ),
             style: { fontSize: '16px' } // Adjust the font size here
          }}
          InputLabelProps={{
            style: { color: theme === 'dark' ? 'white' : 'black' } // Change placeholder color to white
          }}
          inputProps={{
            ...params.inputProps,
            style: { color: theme === 'dark' ? 'white' : 'black' } // Change input text color to white
          }}
          sx={{
            fontSize: '16px', // Adjust the font size here
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme === 'dark' ? 'white' : 'black',
              },
              '&:hover fieldset': {
                borderColor: theme === 'dark' ? 'white' : 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: theme === 'dark' ? 'white' : 'black',
              },
            },
            //background: theme !== 'white' ? 'white' : (theme === 'light' ? '#DBD6C5 !important' : '#565656 !important')
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box 
          component="li" 
          {...props} 
          display="flex" 
          alignItems="center"
          sx={{ 
            backgroundColor: theme === 'white' ? 'white' : (theme === 'light' ? '#DBD6C5' : '#565656'), 
            color: theme === 'dark' ? 'white' : 'black',
            '&:hover': {
              backgroundColor: theme === 'white' ? 'white' : (theme === 'light' ? '#DBD6C5 !important' : '#565656 !important'), 
            },
            '&:last-child': {
              marginBottom: '-8px',
            },
            '&:first-child': {
              marginTop: '-8px',
            },
          }}>
          <Flag code={option.flag} style={{ width: '20px', marginRight: '8px' }} />
          {option.name}
        </Box>
      )}
      
    />
  );
};

export default BookSettingsLanguageAutocomplete;
