import React from 'react';
import PremiumPricingBox from './PremiumPricingBox';

const Premium = () => {
  return (
    <>
      <div>
        <PremiumPricingBox />
      </div>
    </>
  );
};

export default Premium;
